<template>
    <div
        v-if="tableData"
        v-loading="loading"
        v-for="(data, tableName) in tableData"
        :key="tableName"
    >
        <div v-if="data.length">
            <div
                class="default-text--bold"
            >{{ formatTableName(tableName) }}
            </div>
            <el-table
                :data="data"
                :row-class-name="tableRowClassName"
                :show-header="false"
                border
                class="el-table--pmo-status"
                row-key="slug"
            >
                <el-table-column>
                    <template #default="scope">{{ scope.row.amount }}</template>
                </el-table-column>
                <el-table-column>
                    <template #default="scope">{{ formatUnit(scope.row.unit) }}</template>
                </el-table-column>
                <el-table-column>
                    <template #default="scope">{{ scope.row.name }}</template>
                </el-table-column>
                <el-table-column>
                    <template #default="scope">{{ scope.row.description }}</template>
                </el-table-column>
                <el-table-column width="75">
                    <template #default="scope">
                        <div class="buttons">
                            <el-button
                                type="primary"
                                size="small"
                                text
                                @click="editFoodPrescription(scope.row)"
                            >
                                Edit
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>

    <user-food-prescription-edit
        ref="foodEditRef"
        :user="user"
        :prescription="foodPrescription"
    />
</template>

<script setup>
import {onMounted, ref,} from 'vue'
import userApi from '../../userApi'
import UserFoodPrescriptionEdit from "./UserFoodPrescriptionEdit.vue";
import bus from "~/includes/Event";

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
})

const tableData = ref({})
const foodEditRef = ref()
const foodPrescription = ref(null)
const loading = ref(true)
const types = [
    {
        label: 'Increasing the following',
        value: 'increasing'
    },
    {
        label: 'Removing the following will be to your highest benefit',
        value: 'removing benefit'
    },
    {
        label: 'Removing the following based on your preferences',
        value: 'removing preferences'
    },
]

const validUnits = [
    {
        value: 'ml',
        label: 'Milliliters',
    },
    {
        value: 'l',
        label: 'Liters',
    },
    {
        value: 'tsp',
        label: 'Teaspoons',
    },
    {
        value: 'tbsp',
        label: 'Tablespoons',
    },
    {
        value: 'fl oz',
        label: 'Fluid Ounces',
    },
    {
        value: 'cup',
        label: 'Cups',
    },
    {
        value: 'pint',
        label: 'Pints',
    },
    {
        value: 'qt',
        label: 'Quarts',
    },
    {
        value: 'gal',
        label: 'Gallons',
    },
    {
        value: 'mg',
        label: 'Milligrams',
    },
    {
        value: 'g',
        label: 'Grams',
    },
    {
        value: 'kg',
        label: 'Kilograms',
    },
    {
        value: 'oz',
        label: 'Ounces',
    },
    {
        value: 'lb',
        label: 'Pounds',
    },
    {
        value: 'custom',
        label: 'Other',
    }
]
onMounted(() => {
    bus.$on('user-food-rx-updated', () => {
        getData()
    })
})

onMounted(() => getData())

const formatTableName = (tableName) => {
    return types.find(item => item.value === tableName)?.label
}

const formatUnit = (unit) => {
    return validUnits.find(item => item.value === unit)?.label
}

async function getData() {
    loading.value = true
    tableData.value = {}
    const user_id = props.user._id
    const {data} = await userApi.getFoodPrescription(user_id)

    data.forEach(item => {
        const typeValue = item.type;

        if (!tableData.value[typeValue]) {
            tableData.value[typeValue] = [];
        }

        tableData.value[typeValue].push({...item});
    });
    loading.value = false
}

const tableRowClassName = ({row, rowIndex}) => {
    return row.className ?? ''
}

function editFoodPrescription(row) {
    foodPrescription.value = row
    foodEditRef.value.openDialog()
}

</script>

<style lang="scss" scoped>
.buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
}
</style>
