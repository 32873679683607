let collapsed = false
if (window.innerWidth < 768) {
    collapsed = true
}

export const state = {
    isCollapsed: collapsed,
}
export const getters = {
    baseIsCollapsed: state => state.isCollapsed,
}
export const mutations = {
    toggleCollapse(state) {
        state.isCollapsed = !state.isCollapsed
    },

}
