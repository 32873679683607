<template>
    <el-table
        v-loading="loading"
        :data="tableData"
        :row-class-name="tableRowClassName"
        border
        class="el-table--pmo-status"
        row-key="slug"
    >
        <el-table-column
            prop="date"
            label="Date"
            min-width="110"
        >
            <template #default="scope">
                <span v-if="!scope.row.is_goal">
                    {{ convertDateFormat(scope.row.date) }}
                </span>
                <span v-else>
                    Goal
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="height"
            :label="'Height ' + measurementLengthValue"
            min-width="130"
        >
            <template #default="scope">
                {{ cmToFeetAndInches(scope.row.height) }}
                <span v-if="!scope.row.is_goal&&changesData[scope.$index]['height']">
                    ({{ changesData[scope.$index]['height'] }})
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="weight"
            :label="'Weight ' + measurementWeightValue"
            min-width="110"
        >
            <template #default="scope">
                {{ kgToPounds(scope.row.weight) }}
                <span v-if="!scope.row.is_goal&&changesData[scope.$index]['weight']">
                    ({{ changesData[scope.$index]['weight'] }})
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="waist"
            :label="'Waist ' + measurementLengthValue"
            min-width="130"
        >
            <template #default="scope">
                {{ cmToFeetAndInches(scope.row.waist) }}
                <span v-if="!scope.row.is_goal&&changesData[scope.$index]['waist']">
                    ({{ changesData[scope.$index]['waist'] }})
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="bmi"
            label="BMI"
            min-width="110"
        >
            <template #default="scope">
                {{ scope.row.bmi }}
                <span v-if="!scope.row.is_goal&&changesData[scope.$index]['bmi']">
                    ({{ changesData[scope.$index]['bmi'] }})
                </span>
            </template>
        </el-table-column>
        <!-- <el-table-column
            prop="bp"
            label="BP"
            min-width="110"
        /> -->
        <el-table-column label="BP">
            <el-table-column
                prop="systolic"
                label="Systolic"
                min-width="110"
                syle="text-align: center"
            >
                <template #default="scope">
                    {{ scope.row.systolic }}
                    <span v-if="!scope.row.is_goal&&changesData[scope.$index]['systolic']">
                        ({{ changesData[scope.$index]['systolic'] }})
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                prop="diastolic"
                label="Diastolic"
                min-width="110"
            >
                <template #default="scope">
                    {{ scope.row.diastolic }}
                    <span v-if="!scope.row.is_goal&&changesData[scope.$index]['diastolic']">
                        ({{ changesData[scope.$index]['diastolic'] }})
                    </span>
                </template>
            </el-table-column>
        </el-table-column>
        <el-table-column
            prop="glucose"
            label="Glucose"
            min-width="110"
        >
            <template #default="scope">
                {{ scope.row.glucose }}
                <span v-if="!scope.row.is_goal&&changesData[scope.$index]['glucose']">
                    ({{ changesData[scope.$index]['glucose'] }})
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="hba1c"
            label="HbA1c"
            min-width="110"
        >
            <template #default="scope">
                {{ scope.row.hba1c }}
                <span v-if="!scope.row.is_goal&&changesData[scope.$index]['hba1c']">
                    ({{ changesData[scope.$index]['hba1c'] }})
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="triglycerides"
            label="Triglycerides"
            min-width="110"
        >
            <template #default="scope">
                {{ scope.row.triglycerides }}
                <span v-if="!scope.row.is_goal&&changesData[scope.$index]['triglycerides']">
                    ({{ changesData[scope.$index]['triglycerides'] }})
                </span>
            </template>
        </el-table-column>
        <el-table-column
            prop="hdl"
            label="HDL"
            min-width="110"
        >
            <template #default="scope">
                {{ scope.row.hdl }}
                <span v-if="!scope.row.is_goal&&changesData[scope.$index]['hdl']">
                    ({{ changesData[scope.$index]['hdl'] }})
                </span>
            </template>
        </el-table-column>
        <el-table-column
            label="Operations"
            width="120"
        >
            <template #default="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="editBiomarker(scope.row)"
                >
                    Edit
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination
        v-if="total > 0 && total > filters.per_page"
        background
        layout="prev, pager, next"
        :page-size="filters.per_page"
        :total="total"
        @current-change="handleCurrentChange"
    />
    <user-biomarker-edit
        v-if="editBiomarkerVisible"
        :key="biomarker?._id"
        :user="user"
        :biomarker="biomarker"
        @handleClose="handleClose"
    />
</template>

<script setup>
import {onMounted, ref, watch, computed} from 'vue'
import {useRoute} from 'vue-router'
import userApi from '../userApi'
import bus from '~/includes/Event'
import _ from 'lodash'
import { DateTime } from 'luxon'
// import FilterTop from './FilterTop'
import UserBiomarkerEdit from './UserBiomarker/UserBiomarkerEdit'
import store from "~/store";

onMounted(() => {
    bus.$on('user-biomarker-updated', () => {
        getData()
    })
})

const route = useRoute()

const tableData = ref(null)
const companyData = ref(null)
const loading = ref(true)
const changesData = ref(null)

const biomarker = ref(null)
const editBiomarkerVisible = ref(false)
const authUser = computed(() => store.getters['auth/user'])
const measurementLengthValue = computed(() => {
    if (authUser.value.measurements === 'imperial') {
        return '(ft, in)'
    }
    return '(cm)'
})
const measurementWeightValue = computed(() => {
    if (authUser.value.measurements === 'imperial') {
        return '(lb)'
    }
    return '(kg)'
})

const filters = ref({
    // dateRange: '',
    page: 1,
    per_page: 15,
})

const total = ref(0)


const props = defineProps({
    user: {
        type: Object,
        required: true
    },
})

onMounted(() => getData())

watch(() => filters.value, val => {
    getData()
},
{ deep: true }
)

function cmToFeetAndInches(cm) {
    if (authUser.value.measurements === 'imperial') {
        let totalInches = cm / 2.54;
        let feet = Math.floor(totalInches / 12);
        let inches = Math.round(totalInches % 12);

        return `${feet}'${inches}"`;
    }
    return cm
}

function kgToPounds(kg) {
    if (authUser.value.measurements === 'imperial') {
        return Math.floor(kg * 2.205);
    }
    return kg
}

function convertDateFormat(dateString) {
    if (authUser.value.measurements === 'imperial') {
        const date = DateTime.fromISO(dateString);
        return date.toFormat('MM-dd-yyyy');
    }
    return dateString
}

async function getData() {
    loading.value = true
    const user_id = props.user._id

    let params = {
        ...filters.value,
        page: filters.value.page ?? 1,
        per_page: filters.value.per_page ?? 10,
    }

    const {data} = await userApi.getBiomarkers(user_id, params)

    tableData.value = data.data
    total.value = data.total
    loading.value = false

    setChangesData()
}


const tableRowClassName = ({row, rowIndex}) => {
    return row.className ?? ''
}

function handleCurrentChange(page) {
    filters.value.page = page
    getData()
}

function editRow(row) {
    bus.$emit('edit-user-biomarker', row)
}

function setChangesData() {
    changesData.value = _.cloneDeep(tableData.value)

    tableData.value.forEach((row, rowIndex) => {
        if (row.is_goal) {
            return
        }
        for(let key in row) {
            if (key === 'date') {
                continue
            }

            changesData.value[rowIndex][key] = calcChange(row, key, row[key])
        }
    })
}

function calcChange(row, column, cellValue) {
    if (row.is_goal) {
        return ''
    }

    let rowIndex = tableData.value.findIndex(item => item._id === row._id)
    let prevRow = tableData.value[ rowIndex - 1]

    if (!prevRow) {
        return ''
    }

    let prevValue = prevRow[column]

    if (!prevValue) {
        return ''
    }

    let diff = Math.round((cellValue - prevValue) / prevValue * 100)

    if (diff === 0) {
        return ''
    }

    if (diff > 0) {
        return `+${diff}%`
    }

    return diff + '%'
}

function editBiomarker(row) {
    biomarker.value = row
    editBiomarkerVisible.value = true
}

function handleClose() {
    editBiomarkerVisible.value = false
    handleCurrentChange(filters.value.page)
}

</script>

<style lang="scss" scoped>
:deep(.el-table__header thead tr .el-table__cell .cell) {
    text-align: center;
}

</style>
