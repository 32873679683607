<template>
    <el-form
        :model="filters"
        @submit.prevent
    >
        <el-row
            :gutter="10"
            align="middle"
        >
            <el-col
                :sm="12"
                :md="4"
            >
                <el-form-item class="el-form-item--desktop-no-offset">
                    <el-select
                        v-if="companies"
                        v-model="filters.company"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip
                        class="el-select--filter"
                        placeholder="All Co."
                        popper-class="el-popper--companies"
                        fit-input-width
                    >
                        <el-option
                            v-for="item in companies"
                            :key="item._id"
                            :label="item.name"
                            :value="item._id"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :sm="12"
                :md="5"
            >
                <el-form-item class="el-form-item--desktop-no-offset">
                    <el-input
                        v-model="filters.search"
                        placeholder="Search"
                        class="el-input--filter"
                    />
                </el-form-item>
            </el-col>
            <el-col
                :sm="12"
                :md="4"
            >
                <el-form-item class="el-form-item--desktop-no-offset">
                    <el-select
                        v-if="personas"
                        v-model="filters.persona"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip
                        class="el-select--filter"
                        placeholder="All Personas"
                        popper-class="el-popper--personas"
                        fit-input-width
                    >
                        <el-option
                            v-for="item in personas"
                            :key="item._id"
                            :label="item.name"
                            :value="item._id"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :sm="12"
                :md="4"
            >
                <el-form-item class="el-form-item--desktop-no-offset">
                    <el-select
                        v-if="statuses"
                        v-model="filters.status"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip
                        class="el-select--filter large-select"
                        placeholder="All Statuses"
                        popper-class="el-popper--statuses"
                        style="width: 250px; max-width: 100%"
                    >
                        <el-option
                            v-for="item in statuses"
                            :key="item._id"
                            :label="changeStatusLabel(item.name)"
                            :value="item._id"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col
                :sm="12"
                :md="4"
            >
                <el-form-item class="el-form-item--desktop-no-offset">
                    <el-select
                        v-if="sort_by_options"
                        v-model="filters.sort_by"
                        collapse-tags
                        collapse-tags-tooltip
                        class="el-select--filter large-select"
                        placeholder="Sort by"
                        popper-class="el-popper--sort_by"
                        fit-input-width
                    >
                        <el-option
                            v-for="item in sort_by_options"
                            :key="item._id"
                            :label="item.name"
                            :value="item._id"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <!-- <el-col
                :sm="12"
                :md="4"
            >
                <el-form-item>
                    <el-checkbox
                        v-model="filters.last_updates"
                        label="Last updates"
                        placeholder="Last updates"
                    />
                </el-form-item>
            </el-col> -->
            <el-col
                :sm="12"
                :md="2"
            >
                <el-form-item class="el-form-item--desktop-no-offset">
                    <el-button
                        type="primary"
                        class="w-100"
                        round
                        @click="clearSearch"
                    >
                        Reset
                    </el-button>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import companyApi from '../companyApi'
import userApi from '../userApi'
import { useModelWrapper } from '~/includes/modelWrapper'

const emit = defineEmits()

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
})

const filters = useModelWrapper(props, emit, 'modelValue')

const companies = ref(null)
const sort_by_options = ref([
// Last update up/down: it filters by recent modification that is done by users.
// Last login up/down: it filters by recent login.
// Alphabetical up/down.
// Activation up/down.
// Status up/down.
    {
        _id: 'last_update_up',
        name: 'Last update up'
    },
    {
        _id: 'last_update_down',
        name: 'Last update down'
    },
    {
        _id: 'last_login_up',
        name: 'Last login up'
    },
    {
        _id: 'last_login_down',
        name: 'Last login down'
    },
    {
        _id: 'alphabetical_up',
        name: 'Alphabetical up'
    },
    {
        _id: 'alphabetical_down',
        name: 'Alphabetical down'
    },
    {
        _id: 'activation_up',
        name: 'Activation up'
    },
    {
        _id: 'activation_down',
        name: 'Activation down'
    },
    {
        _id: 'status_up',
        name: 'Status up'
    },
    {
        _id: 'status_down',
        name: 'Status down'
    },
])

const personas = ref(null)
const statuses = ref(null)

onMounted(() => {
    loadCompanies()
    loadUsers()
})

async function loadCompanies() {
    const {data} = await companyApi.fetchCompaniesByCoach()
    companies.value = data
}

// const coachUsers = ref(null)

const changeStatusLabel = (label) => {
    return label.replace(/\bpmo\b/gi, "PMO")
}

async function loadUsers() {
    const {data} = await userApi.getCoachUsers({})

    let users = data

    personas.value = []
    statuses.value = []
    users.forEach(user => {
        if (user.personal_type && !personas.value.find(item => item._id === user.personal_type)) {
            personas.value.push({
                _id: user.personal_type,
                name: user.personal_type
            })
        }
        if (user.last_step && !statuses.value.find(item => item._id === user.last_step.path)) {
            if (user.last_step.path) {
                statuses.value.push({
                    _id: user.last_step.path,
                    name: user.last_step.path,
                    level: user.last_step.level
                })
            }
        }

        statuses.value.sort((a, b) => {
            return a.level - b.level
        })
    })

}

function clearSearch() {
    filters.value.search = ''
    filters.value.company = null
    filters.value.sort_by = null
    filters.value.persona = null
    filters.value.status = null
}

</script>

<style lang="scss" scoped>
.large-select {
    width: 400px !important;
    max-width: 100% !important;
}
</style>
