<template>
    <h1 class="header__text">
        {{ title }}
    </h1>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import bus from '~/includes/Event'

const title = ref('SeekingSimple Coaching')
const loading = ref(false)

onMounted(() => {
    bus.$on('load-title', loadTitle)
    bus.$on('set-title', setTitle)
})

function setTitle(title_value) {
    title.value = title_value
    loading.value = false
}

function loadTitle(val) {
    if (val) {
        loading.value = true
        title.value = '.'
        let inter = setInterval(() => {
            loading.value ? title.value += '.' : clearInterval(inter)
        }, 500)
    }
}
</script>
