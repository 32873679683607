import { createRouter, createWebHistory } from 'vue-router'
import { routes } from '~/router/routes'
import store from '~/store'
import bus from '~/includes/Event'

export const MY_ITEM_STEP = 5

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior
})

router.beforeEach(async (to, from) => {
    if (to.meta?.auth === undefined) {
        return true
    }
    if (to.meta.auth && !await isAuth()) {
        return { name: 'Login' }
    }
    if (!to.meta.auth && await isAuth()) {
        return { name: 'Home' }
    }

    if (to.meta.auth) {
        const user = store.getters['auth/user']

        if (!user) return { name: 'Login' }

        const stepLevel = to.meta.step
        if (stepLevel > user.last_step.level) {
            return { path: user.last_step.url }
        }
    }
})

function scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
        if (to.hash) {
            resolve({ selector: to.hash })
        } else if (savedPosition) {
            resolve(savedPosition)
        } else {
            resolve({ x: 0, y: 0 })
        }
    })
}

async function isAuth() {
    if (!store.getters['auth/check'] && store.getters['auth/token']) {
        try {
            await store.dispatch('auth/fetchUser')
            bus.$emit('userLoggedIn')
            return true
        } catch (e) {
        }
    } else if (!store.getters['auth/token']) {
        return false
    }

    return true
}

export default router
