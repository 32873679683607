<template>
    <el-dialog
        v-model="showWelcomePopup"
        :show-close="false"
        width="96%"
        class="el-dialog--welcome-popup welcome-popup"
    >
        <template #header="{}">
            <div class="welcome-popup__header">
                <div class="welcome-popup__header-logo">
                    <inline-svg
                        class="welcome-popup__header-logo-svg"
                        src="/images/auth/seekingsimple-coach-logo.svg"
                    />
                </div>
                <div class="welcome-popup__close">
                    <el-button
                        type="primary"
                        :icon="Close"
                        circle
                        class="welcome-popup__close-btn"
                        @click="closeWelcomePopup"
                    />
                </div>
            </div>
        </template>
        <el-row class="welcome-popup__body">
            <el-col
                :sm="12"
                class="welcome-popup__body-col"
            >
                <div class="welcome-popup__body-col-title">
                    How to use system
                </div>
                <div class="welcome-popup__body-col-text">
                    <p class="default-text default-text--offset-bottom">
                        Humans, or modern humans (Homo sapiens), are the most common and widespread species of primate. A great ape characterized by their hairlessness, bipedalism, and high intelligence, humans have large brains, enabling more advanced cognitive skills that enable them to thrive and adapt in varied environments, and develop complex societies and civilizations.
                    </p>
                    <p class="default-text">
                        Humans are highly social and tend to live in complex social structures composed of many cooperating and competing groups, from families and kinship networks to political states.
                    </p>
                </div>
            </el-col>
            <el-col
                :sm="12"
                class="welcome-popup__body-col welcome-popup__body-col--flex"
            >
                <div class="welcome-popup__body-col-inner">
                    <div class="welcome-popup__body-col-title">
                        User interface
                    </div>
                    <div class="welcome-popup__body-col-text">
                        <p class="default-text default-text--offset-bottom">
                            As such, social interactions between humans have established a wide variety of values, social norms, languages, and rituals, each of which bolsters human society.
                        </p>
                        <p class="default-text">
                            The desire to understand and influence phenomena has motivated humanity's development of science, technology, philosophy, mythology, religion, and other frameworks of knowledge.
                        </p>
                    </div>
                </div>
                <div class="welcome-popup__body-col-image">
                    <div class="welcome-popup__body-col-image-inner">
                        <inline-svg
                            class="welcome-popup__body-col-image-svg"
                            src="/images/welcome-popup-img.svg"
                        />
                    </div>
                </div>
            </el-col>
        </el-row>
        <el-row class="welcome-popup__footer">
            <div class="welcome-popup__footer-checkbox">
                <el-checkbox v-model="notShowAgain">
                    Don't show again
                </el-checkbox>
            </div>
            <div class="welcome-popup__footer-btn-wrap">
                <el-button
                    type="primary"
                    size="large"
                    round
                    class="welcome-popup__footer-btn"
                    @click="clickLearn"
                >
                    Learn
                </el-button>
            </div>
        </el-row>
    </el-dialog>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import store from '~/store'
import userApi from '~/modules/profile/userApi'
import { Close } from '@element-plus/icons-vue'
import InlineSvg from 'vue-inline-svg'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const showWelcomePopup = ref(false)
const notShowAgain = ref(false)

const show_welcome_popup = computed(() => store.getters['auth/show_welcome_popup'])

onMounted(async () => {
    if ((!userApi.hasFirstExperience('welcome-popup')
        && show_welcome_popup.value !== false) ||
        ('welcome' in route.query)) {
        showWelcomePopup.value = true
    }
})

function closeWelcomePopup() {
    showWelcomePopup.value = false
}

function clickLearn() {
    showWelcomePopup.value = false
    router.push({ name: 'learning-modules' })
}

watch(() => showWelcomePopup.value, (value) => {
    if (!value) {
        store.commit('auth/setShowWelcomePopup', false)
        if (notShowAgain.value) {
            userApi.setFirstExperience('welcome-popup')
        }
    }
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.welcome-popup {
    &__close {
        position: absolute;
        @media all and (max-width: $s - 1) {
            top: 5px;
            right: 5px;
        }
        @media all and (min-width: $s) {
            top: 20px;
            right: 20px;
        }
        &-btn {
            padding: 8px;
        }
    }
    &__header {
        display: flex;
        justify-content: center;
        align-items: center;
        &-logo {
            width: 407px;
            height: auto;
            &-svg {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    &__body {
        &-col {
            &:first-child {
                @media all and (max-width: $s - 1) {
                    margin-bottom: 25px;
                }
                @media (min-width: $s) and (max-width: $m - 1) {
                    padding-right: 15px;
                }
                @media all and (min-width: $m) {
                    padding-right: 30px;
                }
            }
            &:nth-child(2) {
                @media (min-width: $s) and (max-width: $m - 1) {
                    padding-left: 15px;
                }
                @media all and (min-width: $m) {
                    padding-left: 30px;
                }
            }
            &-title {
                margin-bottom: 15px;
                font-size: 18px;
                font-weight: bold;
                line-height: 20px;
                color: $dark-middle;
            }
            &-text {}
            &-image {
                max-width: 160px;
                @media all and (max-width: $m - 1) {
                    margin: 0 auto;
                    padding-top: 15px;
                }
                @media all and (min-width: $m) {
                    margin-left: 20px;
                }
                &-inner {
                    width: 160px;
                    height: 280px;
                }
                &-svg {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
            &--flex {
                @media all and (min-width: $m) {
                    display: flex;
                }
            }
        }
    }
    &__footer {
        justify-content: flex-end;
        @media all and (max-width: $m - 1) {
            padding-top: 20px;
        }
        @media all and (min-width: $m) {
            padding-top: 40px;
        }
        &-btn {
            &-wrap {
                margin-left: 20px;
            }
        }
    }
}
</style>
