<template>
    <div class="main-content">
        <div class="main-content--base-layout">
            <div class="main-content__top-content-section">
                <div class="profile-info">
                    <el-form
                        ref="resetPasswordForm"
                        :model="form"
                        class="profile-info__form"
                        @submit.prevent="passwordReset"
                    >
                        <div class="profile-info__form-section">
                            <div class="profile-info__form-section-title">
                                Reset Password
                            </div>
                            <el-form-item
                                prop="password"
                                class="profile-info__form-section-item"
                                :error="formErrors.get('password')"
                            >
                                <label class="el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.password"
                                        name="password"
                                        :type="showPassword ? 'text' : 'password'"
                                        placeholder=" "
                                        class="el-input--default el-input--floating-label el-input--password"
                                    >
                                        <template #prefix>
                                            <i
                                                v-if="form.password.length > 0"
                                                class="el-input__prefix-icon fa"
                                                :class="{
                                                'fa-eye-slash' : !showPassword,
                                                'fa-eye' : showPassword,
                                            }"
                                                @click="showPassword = !showPassword"
                                            />
                                        </template>
                                        <template #suffix>
                                            New Password
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                            <el-form-item
                                prop="password"
                                class="profile-info__form-section-item"
                                :error="formErrors.get('password_confirmation')"
                            >
                                <label class="el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.password_confirmation"
                                        name="password_confirmation"
                                        :type="showPasswordConfirmation ? 'text' : 'password'"
                                        placeholder=" "
                                        class="el-input--default el-input--floating-label el-input--password"
                                    >
                                        <template #prefix>
                                            <i
                                                v-if="form.password_confirmation.length > 0"
                                                class="el-input__prefix-icon fa"
                                                :class="{
                                                'fa-eye-slash' : !showPasswordConfirmation,
                                                'fa-eye' : showPasswordConfirmation,
                                            }"
                                                @click="showPasswordConfirmation = !showPasswordConfirmation"
                                            />
                                        </template>
                                        <template #suffix>
                                            Confirm Password
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <div v-if="showSuccessMessage" class="success-message">
                    Your password has been changed successfully.
                </div>
            </div>
            <div class="main-content__bottom-content-section">
                <div class="main-content__buttons-wrap main-content__buttons-wrap--wrap">
                    <div class="main-content__button-col">
                        <el-button
                            type="primary"
                            round
                            class="continue-button"
                            @click.prevent="passwordReset"
                        >
                            Save Password
                        </el-button>
                    </div>
                    <div class="main-content__button-col">
                        <el-button
                            type=""
                            round
                            class="continue-button"
                            @click="onCancel"
                        >
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue'
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import bus from '~/includes/Event'
import {ElMessage} from 'element-plus'
import {Errors} from '~/includes/Errors'

const router = useRouter()
const route = useRoute()

const user = store.getters['auth/user']

onMounted(() => {
    bus.$emit('set-title', 'Reset Password')
})

const form = ref({
    password: '',
    password_confirmation: '',
})

const formErrors = ref(new Errors())
const showSuccessMessage = ref(false)
let showPassword = ref(false)
let showPasswordConfirmation = ref(false)

function passwordReset() {
    formErrors.value.record([])
    axios.patch('settings/password', form.value).then(response => {
        form.value.password = ''
        form.value.password_confirmation = ''
        showSuccessMessage.value = true
    }).catch(error => {
        if (error.response.data.errors) {
            formErrors.value.record(error.response.data.errors)
        }
    })
}

function onCancel() {
    router.push('/profile')
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.main-content {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.success-message {
    text-align: center;
    color: green;
}
</style>