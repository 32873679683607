<template>
    <div
        class="chapter-module module-block module-block--vertical"
        :class="{ 'chapter-module--done': chapter.completed }"
        @click="goToLesson"
    >
        <div class="chapter-module__image module-block__image">
            <div class="module-block__image-wrap">
                <img
                    :src="chapter.icon_url"
                    class="module-block__image-i"
                    alt=""
                >
            </div>
            <span
                v-if="chapter.completed"
                class="module-block__check-wrap"
            >
                <i
                    class="fa-solid fa-circle-check module-block__check"
                />
            </span>
        </div>
        <div class="chapter-module__title module-block__title">
            {{ chapter.title }}
        </div>
    </div>
</template>

<script setup>

import store from '~/store'

const name = 'LearningModuleChapter'
const props = defineProps({
    chapter: {
        type: Object,
        required: true,
    }
})
function goToLesson() {
    store.commit('learning-modules/switchToShowLesson', true)
    store.commit('learning-modules/setActiveLessonId', props.chapter.lesson._id)
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
    .chapter-module {
        $block-name: &;
        &__image {
            border-color: $light-gray;
            cursor: pointer;
            transition: 0.3s box-shadow ease-in-out;
            &:after {
                background-color: $indigo;
            }
            &:hover {
                box-shadow: 0 0 2px rgba(0,0,0,0.25);
            }
        }
        &__title {
            font-weight: 600;
            color: $dark;
        }
        &--done {
            #{$block-name} {
                &__image {
                    border-color: $indigo;
                }
            }
        }
    }
</style>
