<template>
    <template v-if="coachUser">
        <div class="user-details">
            <div class="user-details__main">
                <div class="user-details__main-row user-details__main-row--offset-top-sm">
                    <div
                        class="user-details__back-link"
                        @click="goBack"
                    >
                        <i class="user-details__back-link-icon fa-solid fa-chevron-left" />
                        <span class="user-details__back-link-title">
                            All Users
                        </span>
                    </div>
                </div>
                <div class="user-details__main-row user-details__main-row--flex user-details__main-row--offset-top-m">
                    <div class="user-details__main-col">
                        <div class="user-details__main-image">
                            <img
                                :src="coachUser.photo_url || '/images/ava-default.svg'"
                                class="user-details__main-image-i"
                                alt=""
                                @error="onErrorImage"
                            >
                        </div>
                    </div>
                    <div class="user-details__main-col">
                        <div class="user-details__name">
                            {{ coachUser.first_name }} {{ coachUser.last_name }}
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                Client ID
                            </div>
                            <div class="user-details__item-value">
                                ID number
                            </div>
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                Activation
                            </div>
                            <div class="user-details__item-value">
                                {{ getActivationDate(coachUser) }}
                            </div>
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                Completion
                            </div>
                            <div class="user-details__item-value">
                                {{ getCompletionDate(coachUser) }}
                            </div>
                        </div>
                    </div>
                    <div class="user-details__main-col">
                        <div class="user-details__contacts">
                            <div class="user-details__contacts-item">
                                <div class="user-details__contacts-item-link">
                                    <inline-svg
                                        class="user-details__contacts-item-link-svg"
                                        src="/images/svg-icons/bell-icon.svg"
                                    />
                                </div>
                            </div>
                            <div class="user-details__contacts-item">
                                <div class="user-details__contacts-item-link">
                                    <inline-svg
                                        class="user-details__contacts-item-link-svg"
                                        src="/images/svg-icons/letter-icon.svg"
                                    />
                                </div>
                            </div>
                            <div class="user-details__contacts-item">
                                <div class="user-details__contacts-item-link">
                                    <inline-svg
                                        class="user-details__contacts-item-link-svg"
                                        src="/images/svg-icons/message-dots-icon.svg"
                                    />
                                </div>
                            </div>
                            <div class="user-details__contacts-item">
                                <div class="user-details__contacts-item-link">
                                    <inline-svg
                                        class="user-details__contacts-item-link-svg"
                                        src="/images/svg-icons/user-phone-icon.svg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                Status
                            </div>
                            <div class="user-details__item-value">
                                {{ (coachUser.last_step&&coachUser.last_step.path) || '—' }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="user-details__main-row user-details__main-row--flex user-details__main-row--offset-top-l">
                    <div class="user-details__main-col">
                        <div class="user-details__main-col-title">
                            Contacts
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                FDM
                            </div>
                            <div class="user-details__item-value">
                                {{ coachUser.is_fdm ? 'Yes' : 'No' }}
                            </div>
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                Accept TXT
                            </div>
                            <div class="user-details__item-value">
                                {{ coachUser.is_accept_txt ? 'Yes' : 'No' }}
                            </div>
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                Store
                            </div>
                            <div class="user-details__item-value">
                                {{ coachUser.primary_store?.banner || "—" }} {{ coachUser.primary_store?.name }}
                            </div>
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                Contacts
                            </div>
                            <div class="user-details__item-value">
                                {{ coachUser.contacts || "—" }}
                            </div>
                        </div>
                    </div>
                    <div class="user-details__main-col">
                        <div class="user-details__main-col-title">
                            Personal data
                        </div>
                        <div class="user-details__item">
                            <!-- <div class="user-details__item-name">
                                Company name
                            </div>
                            <div class="user-details__item-value">
                                {{ coachUser.company && coachUser.company.name }}
                            </div> -->
                            <div class="user-details__item-name">
                                Client level
                            </div>
                            <div class="user-details__item-value">
                                {{ getClientLevel() }}
                            </div>

                            
                        </div>
                        <div class="user-details__item user-details__item--underline">
                            <div
                                class="user-details__item-name"
                                @click="scrollTo(aspirationRef)"
                            >
                                Aspiration
                            </div>
                            <div class="user-details__item-value">
                                {{ coachUser.aspiration ? firstLaterIsLarge(coachUser.aspiration) : "—" }}
                            </div>
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                Household
                            </div>
                            <div class="user-details__item-value">
                                <div v-if="coachUser.household['household_adults']">
                                    {{ coachUser.household['household_adults'] }} adults
                                </div>
                                <div v-if="coachUser.household['household_teenagers_count']">
                                    {{ coachUser.household['household_teenagers_count'] }} teenagers
                                </div>
                                <div v-if="coachUser.household['household_pre_teens_count']">
                                    {{ coachUser.household['household_pre_teens_count'] }} pre teens
                                </div>
                                <div v-if="coachUser.household['household_kids_toddlers_count']">
                                    {{ coachUser.household['household_kids_toddlers_count'] }} kids toddleers
                                </div>
                                <div v-if="!coachUser.household['household_people_count']">
                                    —
                                </div>
                            </div>
                        </div>
                        <div class="user-details__item user-details__item--underline">
                            <div
                                class="user-details__item-name"
                                @click="scrollTo(personaRef)"
                            >
                                Persona
                            </div>
                            <div class="user-details__item-value">
                                {{ coachUser.personal_type || "—" }}
                            </div>
                        </div>
                    </div>
                    <div class="user-details__main-col">
                        <div class="user-details__main-col-title">
                            Health information
                        </div>
                        <div class="user-details__item">
                            <div class="user-details__item-name">
                                ROC
                            </div>
                            <div class="user-details__item-value">
                                {{ coachUser.roc || "—" }}
                            </div>
                        </div>
                        <!-- <div class="user-details__item">
                            <div class="user-details__item-name">
                                Awareness
                            </div>
                            <div class="user-details__item-value" />
                        </div> -->
                        <div class="user-details__item user-details__item--underline">
                            <div
                                class="user-details__item-name"
                                @click="scrollTo(biomarkerRef)"
                            >
                                Biomarker
                            </div>
                        </div>
                        <div class="user-details__item user-details__item--underline">
                            <div
                                class="user-details__item-name"
                                @click="scrollTo(foodRxPmo)"
                            >
                                Food Rx
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="false"
                    class="user-details__main-row user-details__main-row--no-border user-details__main-row--align-right"
                >
                    <div class="user-details__main-col">
                        <el-button
                            type="primary"
                            class="w-100"
                            round
                        >
                            Add Booking
                        </el-button>
                    </div>
                </div>
            </div>
            <div class="user-details__aside">
                <div class="user-details__aside-section-content">
                    <div
                        ref="aspirationRef"
                        class="user-details__aside-section-title"
                    >
                        Aspiration Data Log
                    </div>
                    <user-aspiration-table
                        ref="aspirationTableRef"
                        :user="coachUser"
                    />
                </div>

                <div class="user-details__aside-section">
                    <div class="user-details__aside-section-title">
                        PMO Status
                    </div>
                    <div class="user-details__aside-section-content">
                        <user-pmo-table
                            ref="pmoTableRef"
                            :user="coachUser"
                        />
                    </div>
                </div>
                <div class="user-details__aside-section">
                    <div class="user-details__aside-section-title">
                        Product Progress
                    </div>
                    <div class="user-details__aside-section-content" />
                </div>
                <div class="user-details__aside-section">
                    <div
                        ref="personaRef"
                        class="user-details__aside-section-title"
                    >
                        Persona
                    </div>
                    <div
                        class="user-details__aside-section-content default-text default-text--small default-text--dark-middle-color"
                    >
                        <div
                            v-if="userPersonaData && userPersonaData.body"
                            v-for="personaData in userPersonaData.body"
                        >
                            <H3>
                                {{ personaData.title }}
                            </H3>
                            <div class="persona-body" v-html="personaData.body">
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="user-details__aside-section"
                    ref="biomarkerRef"
                >
                    <div class="user-details__aside-section-title">
                        Biomarkers <user-biomarker-add :user="coachUser" />
                    </div>
                    <div class="user-details__aside-section-content">
                        <user-biomarker-table
                            ref="biomarkerTableRef"
                            :user="coachUser"
                        />
                    </div>
                </div>
                <div
                    ref="foodRxPmo"
                    class="user-details__aside-section"
                >
                    <div class="user-details__aside-section-title">
                        User's PMO
                    </div>
                    <div class="user-details__aside-section-content">
                        <food-rx-user-pmo-table
                            :user="coachUser"
                        />
                    </div>
                    <div class="user-details__aside-section-title">
                        Food Prescription review
                        <user-food-prescription-add :user="coachUser" />
                    </div>
                    <div class="user-details__aside-section-content">
                        <user-food-prescription-table
                            :user="coachUser"
                        />
                    </div>
                </div>
            </div>
        </div>
    </template>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import userApi from '../userApi'
import InlineSvg from 'vue-inline-svg'
import UserPmoTable from './UserPmoTable'
import FoodRxUserPmoTable from './FoodRX/UserPMOTable.vue'
import UserFoodPrescriptionAdd from './FoodRX/UserFoodPrescriptionAdd.vue'
import UserFoodPrescriptionTable from './FoodRX/UserFoodPrescriptionTable.vue'
import UserAspirationTable from './UserAspirationTable'
import UserBiomarkerTable from './UserBiomarkerTable'
import UserBiomarkerAdd from './UserBiomarker/UserBiomarkerAdd'
import { personaTypes } from '~/plugins/persona-types.js';

const SS_DOMAIN = window.config.ssURL.replace('/api/', '')

const route = useRoute()
const router = useRouter()
const coachUser = ref(null)

const loading = ref(false)
const noUserFound = ref(false)

const pmoTableRef = ref(null)
const personaRef = ref(null)
const aspirationTableRef = ref(null)
const aspirationRef = ref(null)
const biomarkerTableRef = ref(null)
const biomarkerRef = ref(null)
const foodRxPmo = ref(null)
const userPersonaData = ref({})

onMounted(() => {
    getUserData()
})

function getUserData() {
    loading.value = true

    noUserFound.value = false

    userApi.getCoachUser(route.params.id)
        .then(response => {
            coachUser.value = response.data

            if (!coachUser.value) {
                noUserFound.value = true
            } else {
                userPersonaData.value = personaTypes.find(persona => persona.type === coachUser.value.personal_type) || {}
            }
        })
        .catch(error => {
            // console.log(error)
            noUserFound.value = true
        }).finally(() => {
            loading.value = false
        })
}

function onErrorImage(e) {
    e.target.src = '/images/ava-default.svg'
}
function getActivationDate(user) {
    let activationDate = new Date(user.created_at)
    return _.isDate(activationDate)&&!isNaN(activationDate)? activationDate.toLocaleDateString() : '—'
}
function getCompletionDate(user) {
    let completionDate = new Date(user.created_at)
    completionDate.setDate(completionDate.getDate() + parseInt(user.roc))

    return _.isDate(completionDate)&&!isNaN(completionDate)? completionDate.toLocaleDateString() : '—'
}

function goBack() {
    router.back()
}

const firstLaterIsLarge = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
}

function scrollTo(refElement) {
    const el = refElement
    if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
}

function getClientLevel() {
    // debugger
    const clientLevel = coachUser.value?.client_level;
    const clientLevelParents = coachUser.value?.client_level_parents;

    let cl = clientLevel?.name || '';

    if (clientLevel && clientLevel.level !== '1' && clientLevelParents) {
        const parents = clientLevelParents
            .filter(parent => parent.level !== '0')
            .sort((a, b) => a.level - b.level);

        if (parents.length > 0) {
            const parentsNames = parents.map(parent => parent.name);
            cl = `${parentsNames.join(' > ')} > ${cl}`;
        }
    }

    return cl;
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.user-details {
    $block-name: &;
    $local-light-gray: #dddddd;
    @media all and (min-width: $s) {
        display: flex;
    }
    &__main,
    &__aside {
        @media all and (min-width: $s) {
            flex: 1;
            height: calc(100vh - $xs-header-height);
            overflow: auto;
            flex: 60%;
        }
        @media all and (max-width: $m - 1) {
            padding: 10px 15px;
        }
    }
    &__main {
        @media all and (max-width: $s - 1) {
            margin-bottom: 40px;
        }
        @media all and (min-width: $s) {
            box-shadow: 5px -4px 5px rgba(0, 0, 0, 0.1);
            flex: 40%;
        }
        @media all and (min-width: $m) {
            padding: 10px 24px 10px 30px;
        }
        &-row {
            border-bottom: 1px solid $local-light-gray;
            padding: 15px 0;
            &--flex {
                @media all and (min-width: $m) {
                    display: flex;
                }
            }
            &--offset-top {
                &-sm {
                    padding: 20px 0 15px;
                }
                &-m {
                    padding: 25px 0 15px;
                }
                &-l {
                    padding: 45px 0 15px;
                }
            }
            &--no-border {
                border-bottom: unset;
            }
            &--align-right {
                display: flex;
                justify-content: flex-end;
            }
        }
        &-col {
            @media all and (max-width: $s - 1) {
                margin-bottom: 15px;
            }
            @media (min-width: $s) and (max-width: $m - 1) {
                margin-bottom: 15px;
            }
            @media all and (min-width: $m) {
                width: calc(100% / 3);
                padding: 0 10px;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            &-title {
                margin-bottom: 15px;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.15;
                color: $dark-middle;
                @media all and (max-width: $m - 1) {
                    text-align: center;
                }
                @media all and (min-width: 1440px) {
                    max-width: 50%;
                    padding: 0 10px 0 0;
                    text-align: right;
                }
            }
        }
        &-image {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 120px;
            margin: 0 auto;
            overflow: hidden;
            border: 4px solid $light-gray;
            border-radius: 50%;
            padding: 4px;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 5px solid $white;
                border-radius: 50%;
            }
            &-i {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__back-link {
        display: inline-flex;
        align-items: center;
        color: $indigo;
        cursor: pointer;
        transition: 0.25s color ease-in-out;
        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            font-size: 20px;
        }
        &-title {
            font-size: 14px;
            font-weight: bold;
            line-height: 1.5;
        }
        &:hover {
            color: lighten($indigo, 10%);
        }
    }
    &__name {
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 1.1;
        font-weight: 600;
        @media all and (max-width: $m - 1) {
            text-align: center;
        }
    }
    &__item {
        display: flex;
        align-items: baseline;
        font-size: 12px;
        line-height: 1.3;
        color: $dark-middle;
        &:not(:last-child) {
            margin: 0 0 5px;
        }
        &-name {
            min-width: 75px;
            flex-shrink: 0;
            width: 50%;
            padding: 0 10px 0 0;
            font-weight: 600;
            text-align: right;
        }
        &--underline {
            cursor: pointer;
            transition: 0.2s color ease-in-out;
            #{$block-name} {
                &__item {
                    &-name {
                        text-decoration: underline;
                    }
                }
            }
            &:hover {
                color: $green;
            }
        }
    }
    &__contacts {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: $m - 1) {
            max-width: 200px;
            margin: 0 auto 15px;
        }
        @media all and (min-width: $m) {
            max-width: 160px;
            margin: 0 0 15px;
        }
        &-item {
            height: 20px;
            &-link {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                &-svg {
                    fill: $indigo;
                }
            }
        }
    }
    &__aside {
        @media all and (min-width: $m) {
            padding: 100px 30px 0 24px;
        }
        &-section {
            margin-bottom: 65px;
            &-title {
                margin-bottom: 15px;
                font-size: 18px;
                font-weight: 600;
                text-align: center;
                color: $dark-middle;
            }
        }
        .persona-body {
            margin-bottom: 5px;
        }
    }
}
</style>
