<template>
    <el-container class="auth-page">
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <img
                        class="auth-page__image"
                        src="/images/auth/seekingsimple-logo.svg"
                        alt=""
                    >
                    <img
                        class="auth-page__image_coach"
                        src="/images/auth/coaching.svg"
                        alt=""
                    >
                </div>
            </div>
            <el-card>
                <login-form
                    :errors="authErrors"
                    :loading="loading"
                    @submit="onSubmit"
                />
            </el-card>
        </el-main>
    </el-container>
</template>

<script setup>
import LoginForm from './LoginForm'
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import store from '~/store'
import authApi from '../authApi'

const router = useRouter()

const authErrors = ref({})
const loading = ref(false)

function onSubmit(loginData) {
    loading.value = true
    authApi.login(loginData).then(response => {
        store.commit('auth/setShowWelcomePopup', true)
        store.dispatch('auth/saveToken', {
            token: response.data.token,
            remember: loginData.remember
        }).then(() => {
            router.push({ name: 'Home' })
        })
    }).catch(error => {
        if (error.response?.status === 422) {
            authErrors.value = error.response.data.errors
        } else {
            console.error(error)
        }
    }).finally(() => loading.value = false)
}
</script>


<style scoped>
:deep(.el-form-item.is-error .el-input__wrapper) {
    box-shadow: unset !important;
}
</style>
