export const state = {
    countUnCompleted: 0,
    activeLessonId: null,
    activeQuizId: null,
    isShowLesson: false,
    activeFunctionality: 'lesson',
}

// getters
export const getters = {
    countUnCompleted: state => state.countUnCompleted,
    activeLessonId: state => state.activeLessonId,
    activeQuizId: state => state.activeQuizId,
    isShowLesson: state => state.isShowLesson,
    activeFunctionality: state => state.activeFunctionality,
}

// mutations
export const mutations = {
    setCountUncompleted (state, count) {
        state.countUnCompleted = count
    },
    setActiveLessonId (state, id) {
        state.activeLessonId = id
    },
    setActiveQuizId (state, id) {
        state.activeQuizId = id
    },
    changeActiveFunctionality (state, func) {
        state.activeFunctionality = func
    },
    switchToShowLesson (state, value) {
        state.isShowLesson = value
    },
}
