<template>
    <div class="app">
        <router-view
            :key="$route.meta.layout || 'Default'"
            v-slot="{ Component }"
        >
            <component
                :is="Component"
            />
        </router-view>
    </div>
</template>


<script setup>
import { ref } from 'vue'
import store from '~/store'
import {computed} from 'vue'
import bus from '~/includes/Event'

import {useRoute} from 'vue-router'

const route = useRoute()

const messages = computed(() => store.getters['global-robot/messages'])
const points = computed(() => store.getters['global-robot/points'])
const type = computed(() => store.getters['global-robot/type'])
const robotImage = computed(() => store.getters['global-robot/image'])

const messageTimeout = ref(null)
function finishOneMessage() {
    if (messageTimeout.value) clearTimeout(messageTimeout.value)
    messageTimeout.value = setTimeout(() => store.commit('global-robot/clear'), 4000)
}

const pointsTimeout = ref(null)
function finishOnePoints() {
    store.commit('global-robot/clearPoints')
}

bus.$on('userLoggedIn', startWebSocketListeners)
bus.$on('logout', stopWebSocketListeners)

function startWebSocketListeners() {
    const token = computed(() => store.getters['auth/token'])
    const user = computed(() => store.getters['auth/user'])

}

function stopWebSocketListeners() {
    const user = computed(() => store.getters['auth/user'])
}

</script>
