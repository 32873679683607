<template>
    <div class="main main--stretch-height">
        <div
            v-loading="isLoading"
            class="main-content main-content--no-offset"
        >
            <div
                v-if="!isDesktop"
                class="header__left-side"
            >
                <div
                    :key="route.name"
                    class="header__menu"
                    @click="goToLearningModules"
                >
                    <i class="header__menu-icon fa-solid fa-arrow-left" />
                </div>
            </div>
            <div
                v-if="lesson"
                class="lesson"
            >
                <div class="lesson__video">
                    <div class="embedded-video">
                        <vue-vimeo-player
                            ref="player"
                            :video-id="videoId"
                            :autoplay="true"
                        />
                    </div>
                </div>
                <div class="g-container">
                    <div class="lesson__btn-section">
                        <el-button
                            :class="{'btn-primary': !lesson.completed, 'btn-success': lesson.completed}"
                            round
                            type="primary"
                            class="continue-button"
                            :loading="completeLoading"
                            :disabled="isComplete || completeLoading"
                            @click.prevent.stop="completeLesson"
                        >
                            Complete Section
                        </el-button>
                    </div>
                    <div class="lesson__content">
                        <div class="accordion">
                            <el-collapse
                                v-model="activeNames"
                                class="el-collapse--lesson"
                            >
                                <el-collapse-item
                                    v-if="lesson.lesson_refrence.length"
                                    title="References"
                                    name="1"
                                >
                                    <Reference
                                        v-for="reference in lesson.lesson_refrence"
                                        :key="reference.id"
                                        :reference="reference"
                                    />
                                </el-collapse-item>
                                <el-collapse-item
                                    v-if="lesson.transcript"
                                    title="Transcript"
                                    name="2"
                                >
                                    <div
                                        class="accordion__item-body-text default-text"
                                        v-html="lesson.transcript"
                                    />
                                    <div class="text-center">
                                        <el-button
                                            id="transcript_continue"
                                            ref="transcript_continue"
                                            class="continue-button"
                                            :loading="completeLoading"
                                            :disabled="isComplete || completeLoading"
                                            type="primary"
                                            round
                                            @click.prevent.stop="completeLesson"
                                        >
                                            Complete Section
                                        </el-button>
                                    </div>
                                </el-collapse-item>
                                <el-collapse-item
                                    v-if="lesson.tips"
                                    title="Tips"
                                    name="3"
                                    @click="openTipsSection"
                                >
                                    <div class="default-text">
                                        {{ lesson.tips }}
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {vueVimeoPlayer} from 'vue-vimeo-player'
import { ref, onMounted, computed, nextTick, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Reference from './Reference'
import store from '~/store'
import userApi from '~/modules/profile/userApi'
import axios from 'axios'
import learningModuleApi from '~/modules/learning-modules/learningModuleApi'
import UseResize from '~/composable/UseResize'

const route = useRoute()
const router = useRouter()

const emit = defineEmits()
const user = store.getters['auth/user']

const { isDesktop } = UseResize()
const lesson = ref(null)
const timerTimes = ref(0)
const isLoading = ref(false)
const timer = ref(null)
const isComplete = ref(true)
const activeNames = ref([])
const completeLoading = ref(false)
const isHelpful = ref(null)
const transcript_continue = ref(null)
const first_module_tip_messages = {
    sleep: 'Magnesium rich foods like spinach, nuts, seeds and avocados can increase serotonin for a better quality sleep.',
    stress: 'Add high-fiber foods like almonds, hummus and raspberries to increase alertness and decrease perceived stress.',
    weight: 'Eating foods higher in fiber like almonds, hummus and raspberries can help reduce cravings and improve weight loss.',
    energy: 'Cinnamon works to keep blood sugar levels stable, therefore it also helps to stabilize your energy levels.',
    health: 'Utilize dark chocolate. It is high in antioxidants that may give protection from cancer, improve heart health and is good for your overall cholesterol profile.',
}

const robotMessages = ref([
    'Each learning section has a quick tip that you can do right away to help your health.',
    'For example: <br> Drink half your weight in ounces of water early in the day so your sleep is not disrupted.'
])
const first_module_tip_message = computed(() => first_module_tip_messages[user.aspiration])
const lesson_id = computed(() => store.getters['learning-modules/activeLessonId'])

onMounted(async () => {
    getData()
})

function getData() {
    isLoading.value = true
    clearTimeout(timer.value)
    if (!lesson_id.value && timerTimes.value <= 100) {
        timerTimes.value = timerTimes.value + 1
        timer.value = setTimeout(() => {
            getData()
        }, 50)
        return
    } else if (timerTimes.value > 100) {
        isLoading.value = false
        timerTimes.value = 0
        return
    }

    timerTimes.value = 0
    learningModuleApi.fetchLesson(lesson_id.value).then(response => {
        lesson.value = response.data
        if (lesson.value.learning_module_chapter.slug === 'sweet-danger') {
            lesson.value.tips = first_module_tip_message.value
            robotMessages.value = [
                'Each learning section has a quick tip that you can do right away to help your health.',
                'For example: <br>' + first_module_tip_message.value
            ]
        }

        isComplete.value = lesson?.value?.completed

        isLoading.value = false
    })
}
async function completeLesson() {
    completeLoading.value = true
    isComplete.value = true
    await geHelpfulData()
    await makeCompleted()
}

function goToLearningModules() {
    store.commit('learning-modules/switchToShowLesson', false)
}

async function geHelpfulData() {
    const {data} = await axios.post('helpful/get', {
        model_type: 'Lesson',
        model_id: lesson.value._id,
    })
    isHelpful.value = data.value
}

function makeCompleted() {
    learningModuleApi.completeLesson(lesson_id.value).then(async response => {
        if (response.data.is_last) {
            await userApi.setFirstExperience('completed_all_lm')
        }
        completeLoading.value = false
        emit('reload')
        goToLearningModules()
    })
}

async function buttonCallback() {
    await handleHelpful(false)
    makeCompleted()
    await openTipsSection()
}

async function buttonCanselCallback() {
    await handleHelpful(true)
    makeCompleted()
    await openTipsSection()
}

function handleHelpful(val) {
    completeLoading.value = true
    return axios.post('helpful/store', {
        model_type: 'Lesson',
        model_id: lesson.value._id,
        is_helpful: val,
    })
}

const videoId = computed(() => {
    const match = /src="https:\/\/player\.vimeo\.com\/video\/(\d+)\?h=.*"/gm.exec(lesson.value?.embedded)
    return match && match[1]
})

async function openTipsSection() {
    await userApi.setFirstExperience('opened_first_tips_lm')
}


watch(
    () => lesson_id.value,
    val => {
        if (val) {
            getData()
        }
    },
    { immediate: true }
)
</script>
