<template>
    <header class="header-default">
        <div class="g-container">
            <div class="header-default__inner">
                <div class="header-default__left-side">
                    <a
                        href="/"
                        class="header-default__logo"
                    >
                        <img
                            src="/images/logo.png"
                            alt=""
                            class="header-default__logo-i"
                        >
                    </a>
                </div>
                <div
                    v-if="isDesktop"
                    class="header-default__controls"
                >
                    <header-controls :controls-list="controls" />
                </div>
                <div v-else>
                    <div
                        class="header__menu"
                        @click="toggleMenu"
                    >
                        <i
                            class="header__menu-icon fa fa-bars"
                        />
                    </div>
                </div>
                <template v-if="!isDesktop">
                    <div
                        class="header__user-menu"
                        :class="{'menu-open': openMenu}"
                    >
                        <user-menu
                            :controls-list="controls"
                            @close="close"
                        />
                    </div>
                    <div
                        class="header__overlay"
                        :class="{'menu-open': openMenu}"
                        @click="toggleMenu"
                    />
                </template>
            </div>
        </div>
    </header>
</template>

<script setup>
import {ref} from 'vue'
import HeaderControls from '~/base/components/header-controls/HeaderControls'
import UseResize from '~/composable/UseResize'
import UserMenu from '~/base/components/user-menu/UserMenu.vue'

const openMenu = ref(false)
const { isDesktop } = UseResize()

const controls = ref([
    // {
    //     title: 'SeekingSimple Login',
    //     icon: '/images/svg-icons/key-icon.svg'
    // },
    {
        onlyForMobile: false,
        title: 'Calendar',
        icon: '/images/svg-icons/calendar-icon.svg',
        routeName: 'calendar'
    },
    {
        onlyForMobile: false,
        title: 'Users',
        icon: '/images/svg-icons/users-icon.svg',
        routeName: 'users.index'
    },
    {
        onlyForMobile: false,
        title: 'Learning',
        icon: '/images/svg-icons/learning-icon.svg',
        routeName: 'learning-modules'
    },
    {
        onlyForMobile: false,
        title: 'Help',
        icon: '/images/svg-icons/life-ring-icon.svg'
    },
    {
        onlyForMobile: true,
        title: 'Edit profile',
        icon: '/images/svg-icons/user-icon.svg',
        routeName: 'profile.info'
    },
    {
        onlyForMobile: true,
        title: 'Change password',
        icon: '/images/svg-icons/user-icon.svg',
        routeName: 'profile.reset-password'
    },
    {
        onlyForMobile: true,
        title: 'Log Out',
        icon: '/images/svg-icons/user-icon.svg'
    },
])

function toggleMenu() {
    openMenu.value = !openMenu.value
}

function close() {
    openMenu.value = false
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.header-default {
    $block-name: &;
    position: relative;
    height: $xs-header-height;
    background-color: $white;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
    @media all and (min-width: $xs){
        height: $default-header-height;
    }
    .g-container {
        @media all and (min-width: $m) {
            max-width: 1920px;
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0;
        @media all and (min-width: $xs){
            padding: 18px 0;
        }
    }
    &__left-side {
        display: flex;
        align-items: center;
    }
    &__logo {
        display: block;
        width: 150px;
        height: 30px;
        text-decoration: none;
        @media all and (min-width: $xs){
            width: 180px;
            height: 34px;
            margin-right: 30px;
        }
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__controls {
        padding-top: 5px;
    }
}
</style>
