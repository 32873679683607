<template>
    <section
        id="dialog"
        class="main-content welcome-page"
    >
        <div class="g-container">
            <div class="welcome-page__text">
                <p class="default-text">
                    Welcome to the SeekingSimple Coaching Site.
                </p>
                <p class="default-text">
                    Here is what you can expect over the upcoming days.
                </p>
                <ul>
                    <li>
                        Perform your own Pantry Makeover (PMO) 1.5 to 2 hrs
                    </li>
                    <li>
                        Learning Module for Coaches
                    </li>
                    <li>
                        Review of Coaching Platform
                    </li>
                </ul>
                <p class="default-text">
                    As you go through your journey, much like the individuals you will be coaching, Saavy will be present to assist you.
                </p>
            </div>
            <div class="welcome-page__btn">
                <el-button
                    type="primary"
                    round
                    class="w-100"
                    @click="goToQuizzes"
                >
                    Continue
                </el-button>
            </div>
            <div class="welcome-page__robot">
                <img
                    class="welcome-page__robot-image"
                    src="/images/robots/robot_smile.svg"
                    alt=""
                >
            </div>
        </div>
    </section>
</template>

<script setup>
import {useRouter} from 'vue-router'
import store from '~/store'
import {WIZARD_STEP} from '~/modules/quiz/routes'
import baseApi from '~/base/baseApi'
import bus from '~/includes/Event'
import {onMounted} from 'vue'

const router = useRouter()

onMounted(() => bus.$emit('set-title', 'SeekingSimple Coaching'))

const goToQuizzes = async () => {
    await baseApi.newUserStep(WIZARD_STEP)
    await store.dispatch('auth/updateUserField', {
        last_step: {
            level: WIZARD_STEP,
            name: 'Wizard',
            path: '/quiz',
        }
    })
    await router.push({name: 'Home'})
}
</script>

<style lang="scss" scoped>
    .welcome-page {
        &__text {
            margin-bottom: 20px;
            .default-text {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
        &__btn {
            max-width: 350px;
            margin: 0 auto 40px;
            .el-button {
                height: 40px;
            }
        }
        &__robot {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 185px;
            height: 185px;
            margin: 0 auto;
            &-image {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
</style>
