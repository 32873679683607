import ProfileInfo from './components/Info'
import ResetPassword from './components/ResetPassword'
import Child from '~/base/components/Child'

const meta = {
    auth: true,
    layout: 'Profile',
}

export default [
    {
        path: 'profile',
        component: Child,
        children: [
            {
                path: 'info',
                name: 'profile.info',
                component: ProfileInfo,
                meta,
            },
            {
                path: 'reset-password',
                name: 'profile.reset-password',
                component: ResetPassword,
                meta,
            },
        ]
    },
]
