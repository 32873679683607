// quiz motivations & frustrations

export const state = {
    motivations: {},
    A: 0,
    B: 0,
    C: 0,
    motivation_selected_subtypes: [],
    done_section: [
        {
            type: 'A',
            done: false,
            child: [
                {type: 'AA', done: false},
                {type: 'AB', done: false},
                {type: 'AC', done: false},
            ],
        },
        {
            type: 'B',
            done: false,
            child: [
                {type: 'BA', done: false},
                {type: 'BB', done: false},
                {type: 'BC', done: false},
            ],
        },
        {
            type: 'C',
            done: false,
            child: [
                {type: 'CA', done: false},
                {type: 'CB', done: false},
                {type: 'CC', done: false},
            ],
        },
    ],
    next_type: '',
}

// getters
export const getters = {
    A: state => state.A,
    B: state => state.B,
    C: state => state.C,
    motivation_selected_subtypes: state => state.motivation_selected_subtypes,
    next_type: state => state.next_type,
}

// mutations
export const mutations = {
    setType (state, payload) {
        state.motivations[payload.question_id] = {[payload.type]: Number(payload.score)}
        state.A = _getType(state.motivations, 'A')
        state.B = _getType(state.motivations, 'B')
        state.C = _getType(state.motivations, 'C')
    },
    setSelectedSubtypes (state, payload) {
        state.motivation_selected_subtypes = state.motivation_selected_subtypes.push(payload)
    },
    setNewType (state, payload) {
        state.next_type = payload
    },
}

// actions
export const actions = {
    markTypeAsDone({ commit, state }, payload) {
        let new_type_collection
        let finished_types = _.filter(state.done_section, ['done', false])

        _.forEach(finished_types, (val) => {
            let index = _.findIndex(val.child, ['type', payload])
            if (index >= 0) {
                val.child[index].done = true
                new_type_collection = _.find(val.child, ['done', false])
            }
            let finished_sub_types = _.filter(val.child, ['done', false])
            if (finished_sub_types.length === 0) {
                val.done = true
                new_type_collection = _.find(state.done_section, ['done', false])
            }
        })

        if (_.filter(state.done_section, ['done', false]).length === 0) {
            _.forEach(state.done_section, (val) => {
                val.done = false
                _.forEach(state.child, (val2) => {
                    val2.done = false
                })
            })
            new_type_collection = _.find(state.done_section, ['type', 'A'])
        }

        commit('setNewType', new_type_collection.type)
    }
}

function _getType(arr, type) {
    return _.reduce(arr, (sum, o) => (sum += o[type] || 0), 0)
}
