<template>
    <el-container
        v-loading="loadingLogo"
        class="auth-page"
    >
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <img
                        class="auth-page__image"
                        src="/images/auth/seekingsimple-logo.svg"
                        alt=""
                    >
                    <img
                        class="auth-page__image_coach"
                        src="/images/auth/coaching.svg"
                        alt=""
                    >
                </div>
            </div>
            <el-card>
                <el-form
                    ref="registerForm"
                    :model="form"
                    :rules="rules"
                    class="auth-form"
                    @keyup.enter="onSubmit"
                >
                    <el-form-item>
                        <div class="auth-form__title">
                            Create Account
                        </div>
                    </el-form-item>

                    <el-form-item
                        prop="email"
                        class="el-form-item--default"
                        :error="formErrors.get('email')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.email"
                                name="email"
                                type="email"
                                placeholder=" "
                                autocomplete="true"
                                :disabled="true"
                                class="el-input--default el-input--floating-label"
                            >
                                <template #suffix>
                                    Email
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="password"
                        :error="formErrors.get('password')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.password"
                                name="password"
                                :type="showPassword ? 'text' : 'password'"
                                placeholder="&nbsp;"
                                class="el-input--floating-label el-input--password"
                                autocomplete="new-password"
                            >
                                <template #prefix>
                                    <i
                                        v-if="form.password.length > 0"
                                        class="el-input__prefix-icon fa eye-icon"
                                        :class="{
                                            'fa-eye-slash' : !showPassword,
                                            'fa-eye' : showPassword,
                                        }"
                                        @click="showPassword = !showPassword"
                                    />
                                </template>
                                <template #suffix>
                                    Password
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="password_confirmation"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.password_confirmation"
                                name="password_confirmation"
                                :type="showConfirmPassword ? 'text' : 'password'"
                                placeholder="&nbsp;"
                                class="el-input--floating-label el-input--password"
                                autocomplete="new-password"
                            >
                                <template #prefix>
                                    <i
                                        v-if="form.password_confirmation.length > 0"
                                        class="el-input__prefix-icon fa eye-icon"
                                        width="5px"
                                        :class="{
                                            'fa-eye-slash' : !showConfirmPassword,
                                            'fa-eye' : showConfirmPassword,
                                        }"
                                        @click="showConfirmPassword = !showConfirmPassword"
                                    />
                                </template>
                                <template #suffix>
                                    Confirm password
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item>
                        <p class="default-text">
                            Feel secure that we <b>never</b> share your personal information with anyone <b><a
                                href="/privacy-policy"
                                class="el-link el-link--dark"
                            >Privacy Policy</a></b>
                        </p>
                    </el-form-item>
                    <el-form-item
                        prop="contact_agree"
                        label=""
                    >
                        <el-checkbox
                            v-model="form.contact_agree"
                            label="I agree to the "
                            name="contact_agree"
                        />
                        <span class="el-link__wrap"><a
                            href="/terms-of-use"
                            class="el-link el-link--dark"
                        >terms of service</a></span>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            :disabled="!form.contact_agree"
                            type="primary"
                            round
                            class="w-100"
                            @click.prevent="onSubmit"
                        >
                            Accept Invitation
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
import {Errors} from '~/includes/Errors'
import validatePassword from '~/includes/mixins/validatePassword'
import authApi from '../authApi'
import { ElLoading, ElMessageBox, ElMessage } from 'element-plus'

export default {
    mixins: [validatePassword],
    layout: 'Auth',
    data() {
        return {
            form: {
                email: '',
                password: '',
                password_confirmation: '',
                loginPath: '',
                contact_agree: false
            },
            showPassword: false,
            showConfirmPassword: false,
            loadingLogo: false,
            rules: {
                email: [
                    { required: true, message: 'Please enter your email', trigger: 'blur' },
                    { type: 'email', message: 'Invalid email', trigger: 'blur' }
                ],
                first_name: [
                    { required: true, message: 'Please enter your first name', trigger: 'blur' }
                ],
                last_name: [
                    { required: true, message: 'Please enter your last name', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Please enter your password', trigger: 'blur' },
                    { validator: this.checkPasswordComplicated, trigger: ['change', 'blur'] }
                ],
                password_confirmation: [
                    { required: true, message: 'Please confirm password', trigger: 'blur' },
                    { validator: this.checkPassIdentical, trigger: ['change', 'blur'] }
                ],
                contact_agree: [{ validator: this.checkAgree, trigger: ['change', 'blur'] }]
            },
            formErrors: new Errors(),
            loading: null,
        }
    },
    created() {
        this.form.invite_code = this.$route.params.invite_code
        this.form.email = this.$route.params.email
        document.body.removeAttribute('class')
        this.form.help = this.$route.params.help

        authApi.verifyInviteCode({
            invite_code: this.form.invite_code,
            email: this.form.email,
        }).then(response => {
            if (response.data.status !== 'success') {
                this.$router.push('/login')
            } else {
                this.form.first_name = response.data.first_name
                this.form.last_name = response.data.last_name
                // this.form.company_id = response.data.company_id
                this.form.client_level_id = response.data.client_level_id
            }
        }).catch(error => {
            this.$router.push('/login')
        })
    },
    methods: {
        startLoading() {
            this.loading = ElLoading.service({
                lock: true,
                text: 'Loading',
                background: 'rgba(255,255,255, 0.7)',
            })
        },
        onSubmit() {
            this.$refs.registerForm.validate((valid) => {
                if (valid) {
                    this.formErrors.clear()
                    this.getAuthCode()
                }
            })
        },
        getAuthCode() {
            authApi.getAuthCode().then(response => {
                navigator.clipboard.writeText(response.data.user_code)
                window.open(response.data.verification_uri)

                ElMessageBox.confirm( response.data.message).then(() => {
                    this.startLoading()
                    // this.fetchToRegistrationCoachInSS()
                    this.registerUser()
                }).catch((error) => {
                    ElMessage.error( 'Canceled')
                    this.loading.close()
                })
            })
        },
        // fetchToRegistrationCoachInSS() {
        //     // create a new user in SS for coach
        //     authApi.ssCoachRegistration(this.form).then(response => {
        //         // this.form.booking_id = response.data.booking_id
        //         // this.form.staff_id = response.data.staff_id
        //         // this.registerUser()
        //     }).catch((error) => {
        //         console.log('error', error.response)
        //         this.loading.close()
        //     })
        // },
        loginCoach() {
            authApi.login(this.form).then(response => {
                this.$store.dispatch('auth/saveToken', {
                    token: response.data.token,
                    remember: true
                }).then(() => {
                    this.$router.push({ name: 'quiz.welcome' })
                })
            })
        },
        async getMeasurements() {
            let result = 'metric'
            await fetch('https://ipapi.co/json/')
                .then(async (response) =>  {
                    await response.json().then(jsonData => {
                        if (jsonData.country_code) {
                            result =  jsonData.country_code === 'US' ? 'imperial' : 'metric'
                        }
                    });
                })

            return result
        },
        async registerUser() {
            this.form.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            this.form.measurements = await this.getMeasurements()
            authApi.register(this.form).then(response => {
                if (response.data.status === 'booking_error') {
                    this.$message.error(response.data.error_message)
                }
                this.loginCoach()

            }).catch(error => {
                if (error.response.status === 422) {
                    this.formErrors.record(error.response.data.errors)
                    if (error.response.data.errors.invite_code) {
                        this.$message.error(error.response.data.errors.invite_code[0])
                    }
                }
            }).finally(() => this.loading.close())
        },
        checkAgree(rule, value, callback) {
            if (!value) {
                callback(new Error('Please agree to term of service'))
            } else {
                callback()
            }
        }
    },
}
</script>

<style scoped>
:deep(.el-form-item.is-error .el-input__wrapper) {
    box-shadow: unset !important;
}
.eye-icon {
    font-size: 8px;
}
</style>
