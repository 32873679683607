export const personaTypes = [
  {
    type: 'AA',
    body: [
      {
        title: 'How to Coach:',
        body: 'Highlight the importance of authenticity. Encourage goal setting that aligns with personal values, not just external expectations. They see themselves as machines. They will do whatever works, so explain why this program will be successful. They like what is tried and true so explain why our system works. They are goal-oriented so early in the conversation explain the goals, based on their aspiration and the path to success. Encourage them to keep track of what they do – they like to have markers of achievement. For example, if sleep is their aspiration keep track of the number of hours, they get each night and how it relates to how they feel in the morning.'
      },
      {
        title: 'Goal Setting:',
        body: 'Use <b>Objective</b> measures: discuss their % Sugar reduction by category, utilize total - physical metrics that are important to them (aspiration metrics), if health is their aspiration get specific about which health metric. For example, are they looking to improve blood sugar, Blood Pressure or something specific to their health.'
      },
      {
        title: 'Eating Tendency:',
        body: 'They often eat to impress or to network for business. They will overeat if they experience failure in some part of their life. They don’t handle failure well. Give as many guarantees as is honest.'
      },
    ]
  },
  {
    type: 'AB',
    body: [
      {
        title: 'How to Coach:',
        body: 'Promote mindfulness and staying present. Suggest finding joy in depth and commitment, not just constant novelty. They are cheerfully anti-authoritarian. They are apt to ignore what you say and do something they say will be better. Give them conclusions; <b>don’t load them up with details more than necessary.</b> They may downplay the dangers, so give them hard data. They may joke around, even if they are paying attention. Their ranking will be convenience, taste, price in terms of food selection.'
      },
      {
        title: 'Goal Setting:',
        body: 'Use <b>Subjective</b> and <b>Objective</b> measures: Subjective could be the degree they find the process interesting, ask for feedback. They are going to be willing to experiment – so ask: if then/what happened – appeal by curiosity. They will also be motivated by their <b>Objective</b> measures: discuss their % Sugar reduction by category, utilize total reductions for motivation, discuss physical metrics that are important to them (aspiration metrics).'
      },
      {
        title: 'Eating Tendency:',
        body: 'They have a low tolerance for boredom and will eat for diversion and entertainment. They are highly apt to experiment with new foods, so this program suits them.'
      },
    ]
  },
  {
    type: 'AC',
    body: [
      {
        title: 'How to Coach:',
        body: 'Tell them the blunt truth. They are confident they can handle it. They see themselves as bigger than life, almost invincible, so make a strong factual case for your recommendations for the process. Highlight vulnerability as strength. Encourage healthy assertiveness and the recognition of others\' perspectives. They may pay attention to hard data, but gentle persuasion doesn’t get you far. They are black and white thinkers so they will be weak on details. Keep things as simple as you can. They may be bright – they can understand - but for them, thinking is done to get to action.'
      },
      {
        title: 'Goal Setting:',
        body: 'Will be motivated by <b>Subjective</b> measure like how this program will impact their strength, stamina and energy. Their goal is to take action and be in control.'
      },
      {
        title: 'Eating Tendency:',
        body: 'They often eat too much with the emotional need to keep themselves strong. Point out how sugar weakens them in so many ways.'
      },
    ]
  },
  {
    type: 'BA',
    body: [
      {
        title: 'How to Coach:',
        body: 'They do not see themselves as one of the herd; they do whatever it takes to stand out as an individual. Validate their uniqueness. Guide them toward positive self-expression and finding joy in everyday moments. If you ask them questions about their eating, expect long answers. They will love exotic foods - something out of the ordinary. Price will be secondary at times. Listen for longer than you expect when they explain why they eat the way they do. They love self-expression and foods that make them an individual.'
      },
      {
        title: 'Goal Setting:',
        body: 'They will be motivated by <b>Subjective</b> measures, could be the degree they find the process interesting, ask for feedback. They are going to be willing to experiment to be unique – so ask: if then/what happened – appeal by curiosity.'
      },
      {
        title: 'Eating Tendency:',
        body: 'They eat to express their emotions and their individuality. They may be prone to photographing or making food a creative endeavor.'
      },
    ]
  },
  {
    type: 'BB',
    body: [
      {
        title: 'How to Coach:',
        body: 'Emphasize the value of sharing knowledge. Encourage balanced engagement with the world and interpersonal connections. They are often disconnected from their body – they live in their heads. They will absorb the information but may not act on it. Spell out the consequences of a bad diet. Information and education are key, support theories with studies, they want to be the expert. They often are not fussy eaters – convenience and cost are more important.'
      },
      {
        title: 'Goal Setting:',
        body: 'Use <b>Objective</b> measures: discuss their % Sugar reduction by category, utilize total - physical metrics that are important to them (aspiration metrics), if health is their aspiration get specific about which health metric. For example, are they looking to improve blood sugar, Blood Pressure, or something specific to their health.'
      },
      {
        title: 'Eating Tendency:',
        body: 'Fast foods are a real trap for them. They can feel an emotional scarcity and eat as a kind of hoarding if they are fat. They often eat unconsciously, especially if they work on the computer, read, or watch TV while they eat. Mindfulness techniques will be helpful.'
      },
    ]
  },
  {
    type: 'BC',
    body: [
      {
        title: 'How to Coach:',
        body: 'Stress the importance of expressing their needs. Encourage active engagement and setting personal goals without fear of conflict. They will agree with you but may not follow through. Always ask them two or three ways to find out what they want or think. They value harmony so much they don’t want to voice their disagreement. When they disagree, they usually just go silent. They are pleasant but will just not do the program if they disagree. Start by explaining the big picture before getting into the details. Present the steps in small stages to get engagement and not overwhelm. It is very important to get them to act immediately and take the first step (a small manageable one).'
      },
      {
        title: 'Goal Setting:',
        body: 'Use <b>Subjective</b> and <b>Objective</b> measures: Subjective could be the degree they find the process helps them feel they are working co-operatively with the company or family goals. They may be motivated by their <b>Objective</b> measures: energy is an issue for this persona, discuss their % Sugar reduction by category and link it to improvement supporting elevation in their energy level and other aspiration that they chose.'
      },
      {
        title: 'Eating Tendency:',
        body: 'They eat to put themselves to sleep. Sleep here is symbolic: they go to sleep about their own needs, especially their health, think like they are checking out. Monitor what they do, not what they say. They’re really nice but can be stubborn.'
      },
    ]
  },
  {
    type: 'CA',
    body: [
      {
        title: 'How to Coach:',
        body: 'Encourage balance and self-compassion. Use precise language, focusing on areas for growth rather than criticism. They will follow the rules. Your best bet is to just make the rules crystal clear. They think in black and white, so don’t add too many details or nuance when instructing them. They will beat up on themselves if they slip, they are incredibly self-critical, so give them a 10% failure rate as good enough for an “A” and tell them the body has backup systems. Listen to them, hear them, don’t criticize them, they do too much of that already. Give them some success markers to they know they did all right – they tend to be perfectionists and self-critical.'
      },
      {
        title: 'Goal Setting:',
        body: 'Use <b>Objective</b> measures: discuss their % Sugar reduction by category, utilize total - physical metrics that are important to them (aspiration metrics), if health is their aspiration get specific about which health metric. For example, are they looking to improve blood sugar, Blood Pressure, or something specific to their health. Make sure to build in a range for improvement because they tend to be perfectionists, support them in giving themselves latitude for what is well done.'
      },
      {
        title: 'Eating Tendency:',
        body: 'When they don’t follow the eating program, it may be because they think theirs is better or the rules are not clear. They eat to do it right.'
      },
    ]
  },
  {
    type: 'CB',
    body: [
      {
        title: 'How to Coach:',
        body: 'Acknowledge their value beyond helping others. Suggest setting boundaries and self-care without feeling guilty. They may do what you want because they want to please you. So how you treat them will be at least as important as the information you give them when it comes to motivation. They are often motivated more by eating correctly to model for their family than for themselves. And if you can find a partner or group to do your program with them, they will be more faithful. They may need personal support to be assertive about their dietary needs.'
      },
      {
        title: 'Goal Setting:',
        body: 'Use <b>Subjective</b> and <b>Objective</b> measures: Subjective could be the degree they find the process helps them feel they are taking care of themselves, their team and their family. They may be motivated by their <b>Objective</b> measures: discuss their % Sugar reduction by category, utilize total - physical metrics that are important to them (aspiration metrics), if health is their aspiration get specific about which health metric. For example, are they looking to improve blood sugar, Blood Pressure, or something specific to their health. Remind them it is ok to take care of themselves first, then they have health and energy to care for others.'
      },
      {
        title: 'Eating Tendency:',
        body: 'When they eat for reasons other than hunger, they’re probably rewarding themselves for doing so much for people all day. Eating is their reward, sometimes taking the place of the love they didn’t get. Unappreciation feeds their hunger.'
      },
    ]
  },
  {
    type: 'CC',
    body: [
      {
        title: 'How to Coach:',
        body: 'Provide reassurance and focus on building trust. Encourage taking risks and developing self-reliance. They tend to be in their heads a lot. They will handle information better than almost anyone. They have a baseline of anxiety. They will be initially critical, so give them resources (links, books, authorities). They cope with anxiety with information, so give them plenty and ask for questions. Answer them fully – they won’t move until they trust. Then give them detailed instructions – they will follow them.\n' +
          'When making suggestions, give them the downsides first (the work or time or inconvenience). They won’t listen to the upsides until their worries are addressed.'
      },
      {
        title: 'Goal Setting:',
        body: 'Use <b>Objective</b> measures: discuss their % Sugar reduction by category, utilize total - physical metrics that are important to them (aspiration metrics), if health is their aspiration get specific about which health metric. For example, are they looking to improve blood sugar, Blood Pressure, or something specific to their health. They are concerned about their risk or the risks to their family’s health, use these goals and measures to show how they are reducing risk.'
      },
      {
        title: 'Eating Tendency:',
        body: 'When they eat badly, it is commonly to assuage anxiety.'
      },
    ]
  },
]