<template>
    <div class="not-found">
        <div class="not-found__animation-section flex-grow">
            <div class="ufo-contain">
                <div class="tractor-beam" />
                <div class="ufo-upper">
                    <div class="cockpit">
                        <div class="glass" />
                        <div class="alien">
                            <div class="eye" />
                        </div>
                        <div class="glass-edge" />
                    </div>
                </div>
                <div class="ufo-lower">
                    <div class="lights">
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
            </div>
        </div>
        <div class="not-found__content-section">
            <div class="not-found__title default-text">
                {{ title }}
            </div>
            <div class="not-found__text default-text">
                {{ text }}
            </div>
            <div class="not-found__btn">
                <el-button
                    round
                    type="primary"
                    class="w-100"
                    @click="$router.push({ name: 'Home' })"
                >
                    Home page
                </el-button>
            </div>
        </div>
    </div>
</template>

<script setup>
const name = 'NotFound'
const title = 'Oops!'
const text = 'Something went wrong.'
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
    .not-found {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        &__animation-section {
            position: relative;
            overflow: hidden;
        }
        &__content-section {
            text-align: center;
        }
        &__title {
            margin-bottom: 10px;
            font-size: 40px;
            font-weight: 600;
        }
        &__text {
            margin-bottom: 10px;
        }
        &__btn {
            max-width: 400px;
            margin: 0 auto;
        }
    }
    .tractor-beam {
        box-shadow: 0 43px 100px 24px yellow;
        perspective: 200px;
        -webkit-perspective-origin: 50% 100%; /* Chrome, Safari, Opera */
        perspective-origin: 50% 100%;
        &:before {
            content: "";
            background-color: yellow;
            width: 120px;
            height: 500px;
            position: absolute;
            top: 25px;
            left: 50%;
            opacity: 0.6;
            padding: 1px;
            transform-origin: (50%, 0%);
            transform: translate(-50%, 0%) rotateX(45deg);
            animation: lightshift 3s ease-in-out infinite alternate;
        }
    }
    .ufo-contain {
        width: 150px;
        height: 50px;
        margin-top: -50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation: stray 6s ease-in-out infinite alternate;
    }
    .ufo-upper {
        z-index: 2;
        background-color: $gray;
        width: 120px;
        height: 35px;
        position: absolute;
        top: 0;
        left: 50%;
        border-radius: 100%;
        box-shadow: inset 0 7px 0 0 rgba(255, 255, 255, 0.2);
        transform: translate(-50%, 0%);
        .cockpit {
            background-color: $dark;
            width: 50px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 100%;
            box-shadow: -8px 3px 0px 0px $gray;
            transform: translate(-50%, -50%);
            .alien {
                background-color: $green;
                width: 30px;
                height: 40px;
                position: absolute;
                bottom: 0;
                left: 50%;
                border-top-left-radius: 100%;
                border-top-right-radius: 100%;
                box-shadow: inset -6px 3px 0px 0px rgba(255, 255, 255, 0.6);
                transform: translate(-50%, 0);
                .eye {
                    background-color: $white-second;
                    width: 15px;
                    height: 15px;
                    overflow: hidden;
                    position: absolute;
                    top: 20%;
                    left: 50%;
                    border-radius: 50%;
                    box-shadow: inset 0 0 0 0.5px $green;
                    cursor: pointer;
                    transform: translate(-50%, 0);
                    &:before {
                        content: "";
                        background-color: $dark;
                        width: 10px;
                        height: 10px;
                        position: absolute;
                        top: 25%;
                        left: 50%;
                        border-radius: 100%;
                        transform: translate(-50%, 0);
                        animation: iris 6s ease infinite alternate;
                    }
                    &:after {
                        content: "";
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        border-radius: 100%;
                        box-shadow: 0 0 0 40px $green;
                        transform: translate(-50%, -50%);
                        animation: blink 6s ease-out infinite;
                    }
                }
            }
            .glass {
                background-color: rgba(61, 90, 108, 0.4);
                width: 50px;
                height: 50px;
                position: absolute;
                bottom: 0;
                left: 50%;
                border-top-left-radius: 100%;
                border-top-right-radius: 100%;
                border-bottom: 1px solid $gray;
                transform: translate(-50%, 0);
            }
            .glass-edge {
                background-color: transparent;
                width: 50px;
                height: 7.5px;
                overflow: hidden;
                position: absolute;
                bottom: -1px;
                left: 50%;
                border-bottom: 1px solid transparent;
                transform: translate(-50%, 0);
                &:before {
                    content: "";
                    background-color: transparent;
                    width: 50px;
                    height: 15px;
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    border-radius: 100%;
                    box-shadow: -19px 0px 0px 10px $gray, 45px 0px 0px 10px $gray;
                    transform: translate(-50%, 0);
                }
            }
        }
    }
    .ufo-lower {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotateX(70deg);
        .lights {
            background-color: $dark-middle;
            width: 154px;
            height: 154px;
            overflow: hidden;
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 100%;
            transform: translate(-50%, -50%);
            animation: spin 1s linear infinite reverse;
            span {
                background-color: $yellow;
                width: 50%;
                height: 50px;
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                transform-origin: 100% 50%;
                &:nth-child(1) {
                    transform: translate(0%, -50%) rotate(0deg);
                }
                &:nth-child(2) {
                    transform: translate(0%, -50%) rotate(120deg);
                }
                &:nth-child(3) {
                    transform:  translate(0%, -50%) rotate(240deg);
                }
            }
        }
        &:after {
            content: "";
            background-color: $dark-middle;
            width: 150px;
            height: 150px;
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 100%;
            box-shadow: inset -12px 12px 0px 0px rgba(255, 255, 255, 0.6);
            transform: translate(-50%, -50%);
        }
    }
    @keyframes iris {
        0% { top: 25%; left: 50%; }
        5% { top: 25%; left: 50%; }
        10% { top: 12%; left: 62%; }
        28% { top: 12%; left: 62%; }
        34% { top: 32%; left: 50%; }
        48% { top: 32%; left: 50%; }
        64% { top: 24%; left: 48%; }
        70% { top: 24%; left: 48%; }
        86% { top: 32%; left: 42%; }
        100% { top: 25%; left: 50%; }
    }
    @keyframes blink {
        0% { height: 15px; }
        90% { height: 15px; }
        95% { height: 0; }
        100% { height: 15px; }
    }
    @keyframes spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
    @keyframes stray {
        0% { top: 49.5%; left: 50.5%; }
        30% { top: 50.5%; left: 49.5%; }
        50% { top: 50.5%; left: 50.5%; }
        70% { top: 49.5%; left: 49.5%; }
        100% { top: 49.5%; left: 50.5%; }
    }
    @keyframes lightshift{
        0% {
            transform: translate(-50%, 0%) rotateX(45deg);
            opacity: 0.6;
        }
        100% {
            transform: translate(-50%, 0%) rotateX(40deg);
            opacity: 0.5;
        }
    };
</style>
