import PantryWelcome from './components/WelcomeToQuizzesDialog'
import store from '~/store'

export const REGISTER_STEP = 1
export const WIZARD_STEP = 2

const beforeEnter = (to, from) => {
    if (store.getters['auth/user'].last_step?.level > WIZARD_STEP) {
        return {name: 'Home'}
    }
}

const meta = {
    auth: true,
    layout: 'Wizard',
    step: WIZARD_STEP
}


export default [
    {
        path: 'welcome',
        name: 'quiz.welcome',
        component: PantryWelcome,
        meta: {
            auth: true,
            layout: 'Wizard',
            step: REGISTER_STEP
        },
        beforeEnter() {
            if (store.getters['auth/user'].last_step?.url !== '/welcome') {
                return {name: 'Home'}
            }
        }
    },
]
