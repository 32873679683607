import axios from 'axios'

const API_ENDPOINT = 'learning-modules'

export default {

    fetchModules(params) {
        return axios.get(API_ENDPOINT, { params })
    },

    fetchAllLessons() {
        return axios.get(API_ENDPOINT + '/lessons')
    },

    fetchLesson(lesson_id) {
        return axios.get(API_ENDPOINT + '/lessons/' + lesson_id)
    },

    addModuleStep(id) {
        return axios.post(API_ENDPOINT + '/' + id + '/add-module-step')
    },

    completeLesson(lesson_id) {
        return axios.post(API_ENDPOINT + '/lessons/' + lesson_id + '/complete')
    },

    fetchQuiz(quiz_id) {
        return axios.get(API_ENDPOINT + '/quiz/' + quiz_id)
    },

    quizComplete(quiz_id) {
        return axios.post(API_ENDPOINT + '/quiz/complete/' + quiz_id)
    },

    completeModule(module_id) {
        return axios.post(API_ENDPOINT + '/' + module_id + '/complete')
    },

    totalNotCompleted() {
        return axios.post(API_ENDPOINT + '/lessons/' + 'total-not-completed')
    },
}
