import { createApp } from 'vue'
import App from './base/App'
import ElementPlus from 'element-plus'
import dayjs from './plugins/day'
import router from './router'
import store from './store'
import globalMixin from './includes/mixins/globalMixin'
import VueAxios from 'vue-axios'
import axios from './plugins/axios-interceptor'
import filters from './includes/filters'
import $bus from './includes/Event'
import LoadingEx from '~/base/components/LoadingEx'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.component('LoadingEx', LoadingEx)

// app.use(axios)
app.use(store)
app.use(router)
app.use(VueAxios, axios)
app.use(ElementPlus)
app.mixin(globalMixin)

app.config.globalProperties.$config = window.config
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$filters = filters
app.config.globalProperties.$bus = $bus

app.mount('#app')

export default app
