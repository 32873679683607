<template>
    <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        style="margin-top: 15px"
        @submit.prevent="saveBiomarker"
    >
        <el-row>
            <el-col
                :span="12"
                :xs="24"
                :sm="12"
            >
                <el-form-item
                    v-if="!form._id && !userHasGoal"
                    label="Is Goal"
                    :label-width="formLabelWidth"
                    :error="errors.get('is_goal')"
                >
                    <el-switch
                        v-model="form.is_goal"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                    />
                </el-form-item>
                <el-form-item
                    v-if="!form.is_goal"
                    label="Date"
                    prop="date"
                    :label-width="formLabelWidth"
                    :error="errors.get('date')"
                >
                    <el-date-picker
                        v-model="form.date"
                        type="date"
                        class="date-picker"
                        placeholder="Date"
                        :format="browserDateFormat"
                        value-format="YYYY-MM-DD"
                        :disabled-date="disabledDate"
                    />
                </el-form-item>
                <el-form-item
                    :label="'Height ' + measurementLengthValue"
                    prop="height"
                    :label-width="formLabelWidth"
                    :error="errors.get('height')"
                >
                    <el-input
                        v-model="form.height"
                        type="text"
                        placeholder="Height"
                    />
                </el-form-item>
                <el-form-item
                    :label="'Weight ' + measurementWeightValue"
                    prop="weight"
                    :label-width="formLabelWidth"
                    :error="errors.get('weight')"
                >
                    <el-input
                        v-model="form.weight"
                        type="number"
                        min="0"
                        placeholder="Weight"
                    />
                </el-form-item>
                <el-form-item
                    :label="'Waist ' + measurementLengthValue"
                    prop="waist"
                    :label-width="formLabelWidth"
                    :error="errors.get('waist')"
                >
                    <el-input
                        v-model="form.waist"
                        type="text"
                        min="0"
                        placeholder="Waist"
                    />
                </el-form-item>
                <el-form-item
                    label="BMI"
                    prop="bmi"
                    :label-width="formLabelWidth"
                    :error="errors.get('bmi')"
                >
                    <el-input
                        v-model="form.bmi"
                        type="number"
                        min="0"
                        placeholder="BMI"
                    />
                </el-form-item>
                <div class="form__divider">
                    <div class="dp-style" style="width:120px">
                        <span class="form__divider-text">BP</span>
                    </div>
                    <el-form-item
                        label="Systolic"
                        prop="systolic"
                        :label-width="formLabelWidth"
                        :error="errors.get('systolic')"
                    >
                        <el-input
                            v-model="form.systolic"
                            type="number"
                            min="0"
                            placeholder="Systolic"
                        />
                    </el-form-item>
                    <el-form-item
                        label="Diastolic"
                        prop="diastolic"
                        :label-width="formLabelWidth"
                        :error="errors.get('diastolic')"
                    >
                        <el-input
                            v-model="form.diastolic"
                            type="number"
                            min="0"
                            placeholder="Diastolic"
                        />
                    </el-form-item>
                </div>
            </el-col>
            <el-col
                :span="12"
                :xs="24"
                :sm="12"
            >
                <el-form-item
                    label="Glucose"
                    prop="glucose"
                    :label-width="formLabelWidth"
                    :error="errors.get('glucose')"
                >
                    <el-input
                        v-model="form.glucose"
                        type="number"
                        min="0"
                        placeholder="Glucose"
                    />
                </el-form-item>
                <el-form-item
                    label="HbA1c"
                    prop="hba1c"
                    :label-width="formLabelWidth"
                    :error="errors.get('hba1c')"
                >
                    <el-input
                        v-model="form.hba1c"
                        type="number"
                        min="0"
                        placeholder="HbA1c"
                    />
                </el-form-item>
                <el-form-item
                    label="Triglycerides"
                    prop="triglycerides"
                    :label-width="formLabelWidth"
                    :error="errors.get('triglycerides')"
                >
                    <el-input
                        v-model="form.triglycerides"
                        type="number"
                        min="0"
                        placeholder="Triglycerides"
                    />
                </el-form-item>
                <el-form-item
                    label="HDL"
                    prop="hdl"
                    :label-width="formLabelWidth"
                    :error="errors.get('hdl')"
                >
                    <el-input
                        v-model="form.hdl"
                        type="number"
                        min="0"
                        placeholder="HDL"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <div
            :loading="loading"
            class="form__buttons"
        >
            <el-button @click="cancel">
                Cancel
            </el-button>
            <el-button
                type="primary"
                @click="saveBiomarker"
            >
                Save
            </el-button>
        </div>
    </el-form>
</template>

<script setup>
import {onMounted, ref, computed,  watch} from 'vue'
import {useRoute} from 'vue-router'
import userApi from '../../userApi'
import bus from '~/includes/Event'
import {Errors} from '~/includes/Errors'
import store from "~/store";

const emit = defineEmits()

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    biomarker: {
        type: Object,
        required: false,
        default: null
    },
})

const authUser = computed(() => store.getters['auth/user'])
const userMeasurements = computed(() => authUser.value?.measurements || 'metric')

const measurementLengthValue = computed(() => {
    if (authUser.value.measurements === 'imperial') {
        return '(ft, in)'
    }
    return '(cm)'
})
const measurementWeightValue = computed(() => {
    if (authUser.value.measurements === 'imperial') {
        return '(lb)'
    }
    return '(kg)'
})

const browserDateFormat = computed(() => {
    return userMeasurements.value === 'metric' ? 'YYYY/MM/DD' : 'MM/DD/YYYY'
})

const formRef = ref()
const form = ref({
    _id: null,
    user_id: null,
    height: null,
    weight: null,
    waist: null,
    bmi: null,
    systolic: null,
    diastolic: null,
    glucose: null,
    hba1c: null,
    triglycerides: null,
    hdl: null,
    is_goal: false,
    date: null,
})
function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const validateLengthMeasurements = (rule, value, callback) => {
    if (userMeasurements.value === 'metric') {
        if (!/^\d+$/.test(value)) {
            return callback(new Error(`${capitalizeFirstLetter(rule?.field)} must be a number`));
        }
        callback();
    } else {
        const heightPattern = /^(\d+)'(\d{1,2})?"?$/;
        if (!heightPattern.test(value)) {
            return callback(new Error(`${capitalizeFirstLetter(rule?.field)} must be in the format 6\'1" or 6\'`));
        }
        callback();
    }
}

const rules = {
    height: [
        { required: true, message: 'Height is required', trigger: 'blur' },
        { validator: validateLengthMeasurements, trigger: 'blur' }
    ],
    date: [
        { required: true, message: 'Date is required', trigger: 'blur' },
    ],
    weight: [
        { required: true, message: 'Weight is required', trigger: 'blur' },
    ],
    waist: [
        { required: true, message: 'Waist is required', trigger: 'blur' },
        { validator: validateLengthMeasurements, trigger: 'blur' }
    ],
    bmi: [
        { required: true, message: 'Bmi is required', trigger: 'blur' },
    ],
    systolic: [
        { required: true, message: 'Systolic is required', trigger: 'blur' },
    ],
    diastolic: [
        { required: true, message: 'Diastolic is required', trigger: 'blur' },
    ],
    glucose: [
        { required: true, message: 'Glucose is required', trigger: 'blur' },
    ],
    hba1c: [
        { required: true, message: 'Hba1c is required', trigger: 'blur' },
    ],
    triglycerides: [
        { required: true, message: 'Triglycerides is required', trigger: 'blur' },
    ],
    hdl: [
        { required: true, message: 'Hdl is required', trigger: 'blur' },
    ],
}

const errors = ref(new Errors())

const loading = ref(false)

const userHasGoal = ref(true)

onMounted(() => {
    if (props.biomarker) {
        form.value = _.cloneDeep(props.biomarker)
    }
    if(props.user && !form.value._id) {
        form.value.user_id = props.user._id
    }
    if(userMeasurements.value === 'imperial' && form.value.height) {
        form.value.height = cmToFeetAndInches(form.value.height)
    }
    if(userMeasurements.value === 'imperial' && form.value.weight) {
        form.value.height = kgToPounds(form.value.weight)
    }
    if(userMeasurements.value === 'imperial' && form.value.waist) {
        form.value.height = cmToFeetAndInches(form.value.waist)
    }

    hasGoal()
})

function feetAndInchesToCm(feetInches) {
    let [feet, inches] = feetInches.split(/['"]/).map(Number);
    let totalInches = (feet * 12) + inches;
    return Math.round(totalInches * 2.54);
}

function cmToFeetAndInches(cm) {
    let totalInches = cm / 2.54;
    let feet = Math.floor(totalInches / 12);
    let inches = Math.round(totalInches % 12);

    return `${feet}'${inches}"`;
}

function poundsToKg(pounds) {
    if (authUser.value.measurements === 'imperial') {
        return Math.floor(pounds / 2.205);
    }
    return pounds
}
function kgToPounds(kg) {
    if (authUser.value.measurements === 'imperial') {
        return Math.floor(kg * 2.205);
    }
    return kg
}

function saveBiomarker() {
    formRef.value.validate((valid) => {
        if (valid) {
            loading.value = true
            let data = form.value
            if(userMeasurements.value === 'imperial') {
                data.height = feetAndInchesToCm(form.value.height)
                data.weight = poundsToKg(form.value.weight)
                data.waist = feetAndInchesToCm(form.value.waist)
            }
            errors.value.clear()
            userApi.saveBiomarker(data).then(() => {
                bus.$emit('user-biomarker-updated')

                emit('biomarker-updated')
            }).catch(error => {
                if (error.response.data.errors) errors.value.record(error.response.data.errors)

                console.log('error', errors.value)
            }).finally(() => loading.value = false)
        }
    })
}

function cancel() {
    emit('cancel')
}

function disabledDate(time) {
    // return time.getTime() <= Date.now()
    return false // Enable all dates
}

async function hasGoal() {
    loading.value = true
    const user_id = props.user._id

    if (!user_id) return

    let params = {
        is_goal: true,
        page: 1,
        per_page: 1,
    }

    const {data} = await userApi.getBiomarkers(user_id, params)

    if (data.total > 0) {
        userHasGoal.value = true
    } else {
        userHasGoal.value = false
    }
    loading.value = false
}

const formLabelWidth = ref('120px')

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.form {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        text-align: right;
    }
}

:deep(.el-input__prefix) {
    display: none;
}

.dp-style {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
}
</style>
