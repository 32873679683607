import axios from 'axios'

const API_ENDPOINT = 'companies'

export default {
    fetchCompanies() {
        return axios.get(API_ENDPOINT + '/')
    },

    fetchCompaniesByCoach() {
        return axios.get(API_ENDPOINT + '/get-coach-companies')
    }
}
