<template>
    <div class="user-menu">
        <div class="user-menu__inner">
            <div
                class="user-menu__close-btn"
                @click="closeMenu"
            >
                <i class="user-menu__close-btn-i fa-solid fa-xmark" />
            </div>
            <div class="user-menu__menu">
                <div
                    v-for="item in controlsList"
                    :key="item.title"
                    class="user-menu__menu-item"
                >
                    <template v-if="item.title === 'SS Login'">
                        <a
                            class="user-menu__link"
                            :href="redirectToSS"
                            target="_blank"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-svg"
                                    :src="item.icon"
                                />
                            </span>
                            <span class="user-menu__link-title">
                                {{ item.title }}
                            </span>
                        </a>
                    </template>
                    <template v-else-if="item.title === 'Log Out'">
                        <div
                            class="user-menu__link"
                            @click="logout"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-svg"
                                    :src="item.icon"
                                />
                            </span>
                            <span class="user-menu__link-title">
                                {{ item.title }}
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <router-link
                            :to="{ name: item.routeName }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-svg"
                                    :src="item.icon"
                                />
                            </span>
                            <span class="user-menu__link-title">
                                {{ item.title }}
                            </span>
                        </router-link>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import store from '~/store'
import { ElMessageBox } from 'element-plus'
import bus from '~/includes/Event'
import InlineSvg from 'vue-inline-svg'
import { computed } from 'vue'

const props = defineProps({
    controlsList: {
        type: Array,
        default: () => {return []}
    }
})

const router = useRouter()
const user = store.getters['auth/user']
const emit = defineEmits()

const redirectToSS = computed(() => {
    return process.env.MIX_SS_URL + '/login'
})

const imageLoadError = (e) => {
    e.target.src = '/images/ava-default.svg'
}
function closeMenu() {
    emit('close')
}

function logout() {
    ElMessageBox.confirm('Confirm LogOut', 'You will be logged out', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
    }).then(async () => {
        bus.$emit('logout')
        await store.dispatch('auth/logout')
        await router.push({ name: 'Login' })
    })
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.user-menu {
    $local-light-gray: #e0e0e0;
    $block-name: &;
    background-color: $white;
    &__close-btn {
        position: absolute;
        z-index: 10;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        &-i {
            font-size: 18px;
            color: $dark;
            transition: 0.2s color ease-in-out;
        }
        &:hover {
            #{$block-name}__close-btn-i {
                color: $green;
            }
        }
    }
    &__user {
        &-info {
            border-bottom: 1px solid $local-light-gray;
            padding: 40px 15px 12px;
        }
        &-avatar {
            width: 48px;
            height: 48px;
            margin-bottom: 20px;
            border-radius: 50%;
            overflow: hidden;
            &-i {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-name {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 0.15px;
            color: $dark;
        }
        &-company {
            font-size: 15px;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: normal;
            color: $dark;
        }
    }

    &__menu {
        height: calc(100vh - 170px);
        overflow-y: auto;
        padding: 15px;
        &-section {
            margin: 0 8px;
            padding: 15px 0 15px;
            &:not(:last-child) {
                border-bottom: 1px solid $light-gray;
            }
        }
        &-item {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
    &__link {
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 10px;
        font-size: 18px;
        text-decoration: none;
        color: $dark;
        cursor: pointer;
        font-weight: 600;
        transition: 0.2s background-color ease-in-out, 0.2s font-weight ease-in-out, 0.2s color ease-in-out;
        &.router-link-exact-active {
            font-weight: bold;
        }
        &:hover {
            font-weight: 600;
            color: $indigo;
            background-color: $light-indigo;
            #{$block-name}__link-icon-i {
                fill: $indigo;
            }
        }
        &-icon {
            width: 20px;
            height: 20px;
            overflow: hidden;
            margin-right: 10px;
            &-i {
                max-width: 100%;
                max-height: 100%;
                fill: $gray;
                transition: 0.2s fill ease-in-out;
            }
        }
    }
}
</style>
