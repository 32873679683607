<template>
    <div class="daily-schedule">
        <div
            v-for="(item, index) in timeSlots"
            :key="index"
            class="daily-schedule__row"
            :class="{'daily-schedule__row--show-edit-btn': item.participants}"
        >
            <div class="daily-schedule__time">
                <div class="daily-schedule__time-value">
                    {{ item.time.replace(/:00$/, '') }}
                </div>
            </div>
            <div
                v-if="item.participants"
                class="daily-schedule__users"
            >
                <div
                    v-for="(user, num) in item.participants"
                    :key="num"
                    class="daily-schedule__users-item"
                >
                    <template v-if="num <= 2">
                        <div
                            v-if="num <= 2"
                            class="daily-schedule__users-item-image"
                        >
                            <img
                                :src="user.photo_url || '/images/ava-default.svg'"
                                class="daily-schedule__users-item-image-i"
                                alt="avatar"
                                @error="imageLoadError"
                            >
                        </div>
                    </template>
                    <template v-if="num > 2">
                        <div class="daily-schedule__users-counter">
                            <span class="daily-schedule__users-counter-value">
                                +{{ item.participants.length - 3 }}
                            </span>
                        </div>
                    </template>
                </div>
            </div>
            <div class="daily-schedule__info">
                <div class="daily-schedule__info-title">
                    {{ item.title }}
                </div>
                <div class="daily-schedule__info-description">
                    {{ item.description }}
                </div>
            </div>
            <div class="daily-schedule__controls">
                <el-button
                    v-if="item.participants"
                    type="primary"
                    round
                    @click="onJoin(item)"
                >
                    Join
                </el-button>
                <el-button
                    v-if="item.participants"
                    type="primary"
                    circle
                    class="daily-schedule__controls-edit-btn"
                    @click="onEdit(item)"
                >
                    <inline-svg
                        src="/images/svg-icons/edit-pen.svg"
                        fill="#ffffff"
                    />
                </el-button>
            </div>
        </div>

        <meeting-data-modal
            :show-modal="showMeetingDataModal"
            :date="dateMeetingDataModal"
            :description="descriptionMeetingDataModal"
            :loading="popupLoading"
            :description-loading="descriptionLoader"
            :join-url="modalMeetingLink"
            :participants="participants"
            @close="onCloseModal"
        />
    </div>
</template>

<script setup>
import { EditPen } from '@element-plus/icons-vue'
import MeetingDataModal from './MettingDataModal'
import { ref } from 'vue'
import { DateTime } from 'luxon'
import userApi from '~/modules/users/userApi'
import InlineSvg from 'vue-inline-svg'

const emit = defineEmits()

const showMeetingDataModal = ref(false)
const popupLoading = ref(false)
const descriptionLoader = ref(false)
const dateMeetingDataModal = ref('')
const descriptionMeetingDataModal = ref('')
const participants = ref([])
const modalMeetingLink = ref('')
const props = defineProps({
    timeSlots: {
        type: Object,
        required: true,
        default: {}
    }
})

// const data = [
//     {
//         time: '8.00',
//     },
//     {
//         time: '10.00',
//         title: 'Group call',
//         description: 'Introduction call to show the system and preference',
//         participants: [
//             {
//                 user: 'Erich Hoeber',
//                 photo: '/images/ava-default.svg'
//             },
//             {
//                 user: 'Rainn Wilson',
//                 photo: '/images/ava-default.svg'
//             },
//             {
//                 user: 'Erich Hoeber',
//                 photo: '/images/ava-default.svg'
//             },
//             {
//                 user: 'Rainn Wilson',
//                 photo: '/images/ava-default.svg'
//             }
//         ]
//     },
//     {
//         time: '11.00',
//         title: 'Erich Hoeber',
//         description: 'Discussion about diet and diagnosis',
//         participants: [
//             {
//                 user: 'Rainn Wilson',
//                 photo: '/images/ava-default.svg'
//             }
//         ]
//     },
// ]

const onJoin = (slot) => {
    window.open(slot.link, '_blank')
}

const imageLoadError = (e) => {
    e.target.src = '/images/ava-default.svg'
}

const fetchGetMeetingUsers = (timeSlot) => {
    const params = {
        ids: timeSlot.participants.map(participant => participant._id)
    }

    userApi.getCoachUsers(params)
        .then(response => {
            participants.value = response.data
            popupLoading.value = false
        })
        .catch((err) => {
            console.log(err.response)
            popupLoading.value = false
        })
}

const fetchGetAppointmentData = (timeSlot) => {
    descriptionLoader.value = true
    const params = {
        id: timeSlot.id
    }

    userApi.getAppointment(params)
        .then(response => {
            descriptionMeetingDataModal.value = response.data?.additionalInformation
            descriptionLoader.value = false
        })
        .catch((err) => {
            console.log(err.response)
            descriptionLoader.value = false
        })
}

const onCloseModal = () => {
    showMeetingDataModal.value = false
    participants.value = []
}
const onEdit = (timeSlot) => {
    let originalDateTime = DateTime.fromISO(timeSlot.startDateTime)
    let endTime = timeSlot.endTime.replace(/:00$/, '')
    let formattedString = originalDateTime.toFormat('EEE dd/MM/yyyy HH:mm')

    dateMeetingDataModal.value = formattedString + ' - ' + endTime
    descriptionMeetingDataModal.value = timeSlot.description
    modalMeetingLink.value = timeSlot.link
    popupLoading.value = true
    showMeetingDataModal.value = true
    fetchGetMeetingUsers(timeSlot)
    fetchGetAppointmentData(timeSlot)
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.daily-schedule {
    $block-name: &;
    &__row {
        display: flex;
        border: solid $light-gray-second;
        transition: 0.2s background-color ease-in-out;
        @media all and (max-width: $m - 1) {
            min-height: 60px;
            padding: 5px;
        }
        @media all and (min-width: $m) {
            min-height: 72px;
            padding: 10px 5px;
        }
        &:hover {
            #{$block-name} {
                &__controls-edit-btn {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &:first-child {
            border-width: 1px;
        }
        &:not(:first-child) {
            border-width: 0 1px 1px 1px;
        }
        &--show-edit-btn {
            @media all and (max-width: $xs) {
                flex-wrap: wrap;
            }
            &:hover {
                background-color: unset;
            }
            #{$block-name} {
                &__controls-edit-btn {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    &__time {
        align-self: flex-end;
        @media all and (max-width: $m - 1) {
            margin-right: 10px;
        }
        @media all and (min-width: $m) {
            margin-right: 20px;
        }
        &-value {
            font-size: 12px;
            line-height: 1.35;
            color: $dark-middle;
        }
    }
    &__users {
        display: flex;
        align-items: flex-end;
        @media all and (max-width: $m - 1) {
            margin-right: 10px;
        }
        @media all and (min-width: $m) {
            margin-right: 15px;
        }
        &-item {
            border-radius: 50%;
            background-color: $white;
            &:not(:first-child) {
                @media all and (max-width: $m - 1) {
                    margin-left: -20px;
                }
                @media all and (min-width: $m) {
                    margin-left: -15px;
                }
            }
            &-image {
                border: 2px solid $light-gray-second;
                border-radius: 50%;
                overflow: hidden;
                padding: 2px;
                @media all and (max-width: $m - 1) {
                    width: 40px;
                    height: 40px;
                }
                @media all and (min-width: $m) {
                    width: 60px;
                    height: 60px;
                }
                &-i {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 30px;
                    border: 1px solid white;
                }
            }
        }
        &-counter {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: unset;
            border: 1px solid $indigo;
            padding: 1px;
            @media all and (max-width: $m - 1) {
                min-width: 30px;
                height: 30px;
                border-radius: 30px;
            }
            @media all and (min-width: $m) {
                min-width: 36px;
                height: 36px;
                border-radius: 36px;
            }
            &:before {
                content: "";
                position: absolute;
                z-index: 2;
                top: 2px;
                right: 2px;
                bottom: 2px;
                left: 2px;
                background-color: $indigo;
                @media all and (max-width: $m - 1) {
                    border-radius: 28px;
                }
                @media all and (min-width: $m) {
                    border-radius: 32px;
                }
            }
            &-value {
                position: relative;
                z-index: 3;
                font-weight: 600;
                color: $white;
                @media all and (max-width: $m - 1) {
                    font-size: 14px;
                }
            }
        }
    }
    &__info {
        flex-grow: 1;
        align-self: center;
        overflow: hidden;
        @media all and (max-width: $xs) {
            width: 50%;
            margin-right: 0;
        }

        &-title,
        &-description {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $dark-middle;
        }
        &-title {
            font-weight: 600;
            line-height: 1.375;
        }
        &-description {
            font-size: 12px;
            line-height: 1.33;
        }
    }
    &__controls {
        display: flex;
        justify-content: flex-end;
        align-self: center;
        @media all and (max-width: $xs) {
            width: 100%;
        }
        @media all and (max-width: $m - 1) {
            margin-right: 10px;
        }
        @media all and (min-width: $m) {
            margin-right: 15px;
        }
        &-edit-btn {
            width: 36px;
            height: 36px;
            padding: 8px;
            opacity: 0;
            visibility: hidden;
            transition: 0.2s opacity ease-in-out, 0.2s visibility ease-in-out;

            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}
</style>
