import axios from 'axios'
import {ElMessage} from 'element-plus'
import store from '~/store'

let token = document.head.querySelector('meta[name="csrf-token"]')
axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = window.config.baseUrl + '/api'
axios.defaults.withCredentials = false
axios.defaults.timeout = 60 * 1000

// Request interceptor
axios.interceptors.request.use(request => {
    const token = store.getters['auth/token']
    if (token) {
        request.headers['Authorization'] = `Bearer ${token}`
    }

    return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
    if (error.response?.data?.message) {
        console.error('--- ', error.response.data.message)
    }
    if (error.response?.status >= 500) {
        ElMessage.error('Unknown server error!')
    } else if (error.response?.status === 401 && error.response?.data?.message) {
        ElMessage.error(error.response.data.message)
    }

    return Promise.reject(error)
})

export default axios
