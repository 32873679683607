<template>
    <div class="learning-modules">
        <div
            v-if="isDesktop || !isShowLesson"
            class="learning-modules__left"
            ref="leftWidth"
        >
            <div
                v-loading="leftLoading"
                class="g-container"
            >
                <div class="learning-modules__title mt-3">
                    Coach learning modules
                </div>
                <div class="learning-modules__text">
                    Complete these <b>learning modules</b> as the first step in your
                    pantry makeover. Each one just takes a couple of minutes
                </div>

                <div
                    v-if="learning_modules.length !== 0"
                    id="learning-modules"
                >
                    <div
                        v-for="(module, index) in learning_modules"
                        :id="`module-${index + 1}`"
                        :key="module._id"
                        :ref="el => { divs[module._id] = el }"
                    >
                        <LearningModuleItem
                            ref="learningItemRef"
                            :module="module"
                            :is-active="isItemActive(module, index)"
                            :is-all-done="!learning_modules.some(item => !item.completed) && (index + 1) === learning_modules.length"
                        />
                    </div>
                </div>
            </div>
            <el-dialog
                v-model="showFinishQuestion"
                :show-close="false"
                :close-on-click-modal="false"
                custom-class="el-dialog--learning-module"
            >
                <FinishQuestion @clickCompleteSection="closeFinishQuestion"/>
            </el-dialog>
        </div>
        <div
            v-if="isDesktop"
            ref="middle"
            @mousedown="startResize"
            class="learning-modules__between"
        ></div>
        <div
            v-if="isDesktop || isShowLesson"
            class="learning-modules__right"
            ref="rightWidth"
        >
            <Lesson
                v-if="activeRightSide === 'lesson'"
                @reload="reload"
            />
            <Quiz
                v-if="activeRightSide === 'quiz'"
                @reload="reload"
            />
        </div>
    </div>
</template>

<script setup>
import {computed, nextTick, onMounted, ref} from 'vue'
import LearningModuleItem from './LearningModuleItem'
import Quiz from './quiz'
import Lesson from './Lesson'
import store from '~/store'
import userApi from '~/modules/profile/userApi'
import FinishQuestion from './FinishQuestion'
import learningModuleApi from '~/modules/learning-modules/learningModuleApi'
import UseResize from '~/composable/UseResize'

const user = store.getters['auth/user']

const learningItemRef = ref()
const leftLoading = ref(true)
const learning_modules = ref([])
const startX = ref(null)
const initialWidth = ref(null)
const leftWidth = ref(null);
const rightWidth = ref(null);
const middle = ref(null);
const iframe = ref(null);
const divs = ref([])
const timer = ref(null)
const lesson = ref(null)
const showFinishQuestion = ref(false)
const {isDesktop} = UseResize()


const activeModuleId = computed(() => learning_modules.value.find(o => !o.completed)?._id)
const activeRightSide = computed(() => store.getters['learning-modules/activeFunctionality'])
const isShowLesson = computed(() => store.getters['learning-modules/isShowLesson'])

onMounted(() => {
    if (isDesktop.value) {
        handleWidths()
    }
    reload()
    window.addEventListener('mouseup', stopResize)
})

function handleWidths() {
    const pageWidth = window.innerWidth
    leftWidth.value.style.width = Math.round(pageWidth / 2) + 'px';
    rightWidth.value.style.width = Math.round(pageWidth / 2) + 'px';
}

function startResize(event) {
    if (event.buttons === 1) {
        startX.value = event.clientX;
        iframe.value = document.querySelector('iframe')
        iframe.value.style.pointerEvents = 'none';
        initialWidth.value = middle.value.offsetWidth;
        document.addEventListener('mousemove', resizeElement);
    }
}

function resizeElement(event) {
    if (event.clientX > 300 && event.clientX < (window.innerWidth - 300)) {
        leftWidth.value.style.width = event.clientX + 'px';
        rightWidth.value.style.width = window.innerWidth - event.clientX + 'px';
    }
}

function stopResize() {
    if (iframe.value) {
        iframe.value.style.pointerEvents = 'unset';
    }
    document.removeEventListener('mousemove', resizeElement);
    document.removeEventListener('mouseup', stopResize);
}

function isItemActive(module, index) {
    return module._id === activeModuleId.value || (!learning_modules.value.some(item => !item.completed) && (index + 1) === learning_modules.value.length && isDesktop.value)
}
async function reload() {
    if (user.last_step.url !== '/learning-modules') {
        await store.dispatch('auth/fetchUser')
    }
    await fetchModules()
    await userApi.setFirstExperience('learning_modules_intro')
    if (userApi.hasFirstExperience('completed_all_lm') && !userApi.hasFirstExperience('showed_finish_question')) {
        store.commit('shortcut_quiz/showFinalRobotDialog')
    }
    scrollToActive()
}

async function fetchModules() {
    leftLoading.value = true
    const params = {
        type: 'coach'
    }
    const {data} = await learningModuleApi.fetchModules(params)
    learning_modules.value = data?.data
    callChildChapterActivator()
    leftLoading.value = false
}

function callChildChapterActivator() {
    clearTimeout(timer.value)
    if (!learningItemRef.value) {
        timer.value = setTimeout(() => {
            callChildChapterActivator()
        }, 50)
        return
    }
    nextTick(() => {
        learningItemRef.value.forEach(ref => {
            ref.chapterActivator()
        })
    })
}

function scrollToActive() {
    if (activeModuleId.value) {
        divs.value[activeModuleId.value]?.scrollIntoView({behavior: 'smooth'})
    }
}

function closeFinishQuestion() {
    showFinishQuestion.value = false

    router.push({name: 'calendar'})
}

</script>
