<template>
    <el-form
        ref="formRef"
        :model="form"
        :rules="rules"
        style="margin-top: 15px"
        @submit.prevent="saveFoodPrescription"
    >
        <el-row>
            <el-col
                :span="24"
                :xs="24"
                :sm="24"
            >
                <el-form-item
                    label="Type"
                    :label-width="formLabelWidth"
                    :error="errors.get('type')"
                >
                    <el-select
                        v-model="form.type"
                        placeholder=" "
                        name="type"
                        class="w-100"
                    >
                        <el-option
                            v-for="item in types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="Amount"
                    :label-width="formLabelWidth"
                    :error="errors.get('amount')"
                >
                    <el-input
                        v-model="form.amount"
                        type="text"
                        placeholder="Amount"
                    />
                </el-form-item>
                <el-form-item
                    label="Unit"
                    :label-width="formLabelWidth"
                    :error="errors.get('unit')"
                >
                    <el-select
                        class="w-100"
                        v-model="form.unit"
                        placeholder=" "
                        name="unit"
                    >
                        <el-option
                            v-for="item in validUnits"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="Name"
                    :label-width="formLabelWidth"
                    :error="errors.get('name')"
                >
                    <el-input
                        v-model="form.name"
                        type="text"
                        placeholder="Name"
                    />
                </el-form-item>
                <el-form-item
                    label="Description"
                    :label-width="formLabelWidth"
                    :error="errors.get('description')"
                >
                    <el-input
                        v-model="form.description"
                        :rows="4"
                        type="textarea"
                    />
                </el-form-item>
            </el-col>
        </el-row>

        <div

            class="form__buttons"
        >
            <el-button
                :loading="loading"
                @click="cancel"
            >
                Cancel
            </el-button>
            <el-button
                :loading="loading"
                type="primary"
                @click="saveFoodPrescription"
            >
                Save
            </el-button>
            <el-button
                v-if="prescription && prescription._id"
                type="danger"
                @click="deletePrescription"
            >
                Delete
            </el-button>
        </div>
    </el-form>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import userApi from '../../userApi'
import bus from '~/includes/Event'
import {Errors} from '~/includes/Errors'
import { ElMessageBox } from 'element-plus'

const emit = defineEmits()

const props = defineProps({
    user: {
        type: Object,
        required: true,
    },
    prescription: {
        type: Object,
        required: false
    }
})

const formRef = ref(null)
const form = ref({
    _id: null,
    user_id: null,
    type: null,
    amount: null,
    unit: null,
    name: null,
    description: null,
})
const types = [
    {
        label: 'Increasing the following',
        value: 'increasing'
    },
    {
        label: 'Removing the following will be to your highest benefit',
        value: 'removing benefit'
    },
    {
        label: 'Removing the following based on your preferences',
        value: 'removing preferences'
    },
]

const validUnits = [
    {
        value: 'ml',
        label: 'Milliliters',
    },
    {
        value: 'l',
        label: 'Liters',
    },
    {
        value: 'tsp',
        label: 'Teaspoons',
    },
    {
        value: 'tbsp',
        label: 'Tablespoons',
    },
    {
        value: 'fl oz',
        label: 'Fluid Ounces',
    },
    {
        value: 'cup',
        label: 'Cups',
    },
    {
        value: 'pint',
        label: 'Pints',
    },
    {
        value: 'qt',
        label: 'Quarts',
    },
    {
        value: 'gal',
        label: 'Gallons',
    },
    {
        value: 'mg',
        label: 'Milligrams',
    },
    {
        value: 'g',
        label: 'Grams',
    },
    {
        value: 'kg',
        label: 'Kilograms',
    },
    {
        value: 'oz',
        label: 'Ounces',
    },
    {
        value: 'lb',
        label: 'Pounds',
    },
    {
        value: 'custom',
        label: 'Other',
    }
]

const rules = {
    type: [
        {required: true, message: 'Type is required', trigger: 'blur'},
    ],
    name: [
        {required: true, message: 'Name is required', trigger: 'blur'},
    ],
}

const errors = ref(new Errors())

const loading = ref(false)

onMounted(() => {
    if (props.prescription) {
        form.value = _.cloneDeep(props.prescription)
    }
    if (props.user && !form.value._id) {
        form.value.user_id = props.user._id
    }
})


function deletePrescription() {
    ElMessageBox.confirm('This will permanently delete the Food Prescription. Continue?', 'Warning', {
        type: 'warning'
    }).then(() => {
        userApi.deleteFoodPrescription(props.prescription._id)
            .then(() => {
                bus.$emit('user-food-rx-updated')
                cancel()
            })
    })

}

const saveFoodPrescription = () => {
    loading.value = true
    errors.value.clear()
    if (!form.value.user_id) {
        form.value.user_id = props.user._id
    }
    userApi.saveFoodPrescription(form.value).then(() => {
        bus.$emit('user-food-rx-updated')
        cancel()
    }).catch(error => {
        if (error.response.data.errors) errors.value.record(error.response.data.errors)

        console.log('error', errors.value)
    }).finally(() => loading.value = false)
}

function cancel() {
    form.value = {
        _id: null,
        user_id: null,
        type: null,
        amount: null,
        unit: null,
        name: null,
        description: null,
    }
    emit('cancel')
}

watch(() => props.prescription, () => {
    if (props.prescription) {
        form.value = _.cloneDeep(props.prescription)
    }
})

const formLabelWidth = ref('120px')

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.form {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        text-align: right;
    }
}

:deep(.el-input__prefix) {
    display: none;
}

:deep(.el-textarea textarea) {
    background: #e5e8f4;
}
</style>
