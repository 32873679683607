<template>
    <div class="content">
        <div class="main-content">
        <div class="main-content--base-layout">
            <div class="main-content__top-content-section">
                <div class="profile-info">
                    <div class="profile-info__user-info">
                        <el-form
                            :model="form"
                            class="profile-info__form"
                        >
                            <div class="profile-info__form-section">
                                <div class="profile-info__form-section-title">
                                    Main Information
                                </div>
                                <el-form-item
                                    class="profile-info__form-section-item"
                                    :error="formErrors.get('first_name')"
                                >
                                    <label class="el-form-item__label--floating-label">
                                        <el-input
                                            v-model="form.first_name"
                                            placeholder=" "
                                            class="el-input--default el-input--floating-label"
                                        >
                                            <template #suffix>
                                                First Name
                                            </template>
                                        </el-input>
                                    </label>
                                </el-form-item>
                                <el-form-item
                                    class="profile-info__form-section-item"
                                    :error="formErrors.get('last_name')"
                                >
                                    <label class="el-form-item__label--floating-label">
                                        <el-input
                                            v-model="form.last_name"
                                            placeholder=" "
                                            class="el-input--default el-input--floating-label"
                                        >
                                            <template #suffix>
                                                Last Name
                                            </template>
                                        </el-input>
                                    </label>
                                </el-form-item>
                                <el-form-item
                                    class="profile-info__form-section-item"
                                    :error="formErrors.get('email')"
                                >
                                    <label class="el-form-item__label--floating-label">
                                        <el-input
                                            v-model="form.email"
                                            placeholder=" "
                                            class="el-input--default el-input--floating-label"
                                        >
                                            <template #suffix>
                                                Email
                                            </template>
                                        </el-input>
                                    </label>
                                </el-form-item>
                            </div>
                            <div class="profile-info__form-section">
                                <div class="profile-info__form-section-title">
                                    Measurement Settings
                                </div>
                                <div>
                                    Please, select your preferred measurement system.
                                </div>
                                <el-radio-group
                                    v-model="form.measurements"
                                    class="ml-4"
                                >
                                    <el-row>
                                        <el-radio
                                            label="imperial"
                                            size="large"
                                        >
                                            Imperial
                                        </el-radio>
                                    </el-row>
                                    <el-row>
                                        <el-radio
                                            label="metric"
                                            size="large"
                                        >
                                            Metric
                                        </el-radio>
                                    </el-row>
                                </el-radio-group>
                            </div>
                        </el-form>
                    </div>
                </div>
                <div
                    v-if="showSuccessMessage"
                    class="success-message"
                >
                    Your profile has been changed successfully.
                </div>
            </div>
            <div class="main-content__bottom-content-section">
                <div class="main-content__buttons-wrap main-content__buttons-wrap--wrap">
                    <div class="main-content__button-col">
                        <el-button
                            type="primary"
                            round
                            class="continue-button"
                            @click="onSubmit"
                        >
                            Save Profile
                        </el-button>
                    </div>
                    <div class="main-content__button-col">
                        <el-button
                            type=""
                            round
                            class="continue-button"
                            @click="onCancel"
                        >
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script setup>
import {computed, ref} from 'vue'
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import {Errors} from '~/includes/Errors'

const router = useRouter()
const route = useRoute()
const user = computed(() => store.getters['auth/user'])

const formErrors = ref(new Errors())

const form = ref({
    email: user.value.email,
    first_name: user.value.first_name,
    last_name: user.value.last_name,
    measurements: user.value.measurements || 'metric',
})

const showSuccessMessage = ref(false)

const onSubmit = () => {
    formErrors.value.record([])
    axios.patch('/settings/profile', form.value).then(response => {
        store.dispatch('auth/updateUser', response.data)
        showSuccessMessage.value = true
    }).catch(error => {
        console.log(error)
        formErrors.value.record(error.response.data.errors)
    })
}

function onCancel() {
    router.back()
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.content {
    overflow: auto;
    height: calc(100vh - 70px);
    padding: 20px;
}

.profile-info {
    $block-name: &;

    &__avatar {
        &-image {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            transition: 0.2s ease;

            &:hover {
                &:after {
                    opacity: 1;
                    visibility: visible;
                }
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                visibility: hidden;
                opacity: 0;
                background-image: url(/images/ic_add_a_photo_black_24px.svg);
                background-size: 24px 24px;
                background-repeat: no-repeat;
                background-position: center center;
                background-color: rgba(0, 0, 0, 0.5);
                transition: 0.2s visibility ease, 0.2s opacity ease;
            }

            &-i {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
            }

            &-wrap {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding-bottom: 15px;
                @media all and (min-width: $xs) {
                    padding-bottom: 30px;
                }
            }
        }

        &-upload-error {
            display: block;
            margin-bottom: 10px;
            font-size: 12px;
            text-align: center;
            color: $maroon;
        }
    }

    &__form {
        &-section {
            &-row {
                display: flex;
                margin: 0 -5px 15px;

                &:last-child {
                    margin: 0 -5px 0;
                }

                #{$block-name} {
                    &__form-section-item {
                        margin-bottom: 0;
                    }
                }
            }

            &-col {
                width: 33%;
                padding: 0 5px;
                @media all and (min-width: $xs) {
                    width: 25%;
                }
            }

            &-item {
                margin-bottom: 15px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.el-radio-group {
    display: unset;
}

.main-content {
    display: flex;
    justify-content: center;
}

.success-message {
    text-align: center;
    color: green;
}

</style>
