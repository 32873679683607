<template>
    <div class="calendar-index">
        <div
            v-loading="loading"
            class="calendar-index__month"
        >
            <el-calendar
                ref="calendar"
                v-model="form.date"
                :min-date="DateTime.now()"
            >
                <template #header="{ date }">
                    <div class="el-calendar__header-inner">
                        <div class="el-calendar__date">
                            <div class="el-calendar__date-wrap">
                                <div class="el-calendar__date-icon">
                                    <inline-svg
                                        class="el-calendar__date-icon-svg"
                                        src="/images/svg-icons/calendar-icon.svg"
                                    />
                                </div>
                                <div class="el-calendar__date-value">
                                    {{ date }}
                                </div>
                            </div>
                            <div class="el-calendar__controls">
                                <el-button
                                    size="small"
                                    :icon="ArrowLeft"
                                    link
                                    @click="selectDate('prev-month')"
                                />
                                <el-button
                                    size="small"
                                    :icon="ArrowRight"
                                    link
                                    @click="selectDate('next-month')"
                                />
                            </div>
                        </div>
                        <div class="el-calendar__filter">
                            <!-- <el-select
                                v-model="filters.companies"
                                multiple
                                collapse-tags
                                collapse-tags-tooltip
                                class="el-select--filter"
                                placeholder="All Co."
                                popper-class="el-popper--companies"
                                fit-input-width
                            >
                                <el-option
                                    v-for="item in companies"
                                    :key="item._id"
                                    :label="item.name"
                                    :value="item._id"
                                />
                            </el-select> -->
                            <el-tree-select
                                ref="treeClientLevelsRef"
                                v-model="filters.client_levels"
                                :data="clentLevelsTreeData"
                                multiple
                                :render-after-expand="false"
                                show-checkbox
                                :check-strictly="true"
                                :check-on-click-node="false"
                                class="el-select--filter"
                                placeholder="All Client Levels"
                                popper-class="el-popper--client-levels"
                                :fit-input-width="false"
                            />
                        </div>
                        <div class="el-calendar__filter">
                            <el-select
                                v-model="filters.users"
                                multiple
                                collapse-tags
                                collapse-tags-tooltip
                                class="el-select--filter"
                                placeholder="All Users"
                                popper-class="el-popper--companies"
                                fit-input-width
                            >
                                <el-option
                                    v-for="item in usersForFilter"
                                    :key="item._id"
                                    :label="item.first_name + ' ' + item.last_name"
                                    :value="item._id"
                                />
                            </el-select>
                        </div>
                    </div>
                </template>
                <template #date-cell="{ data }">
                    <el-row>
                        <el-col>
                            <p
                                :class="isWeekend(data.day) && !data.isSelected ? 'disabled-text' : ''"
                                class="default-text--align-right"
                            >
                                {{ data.day.split('-')[2] }}
                            </p>
                        </el-col>
                    </el-row>

                    <div
                        v-for="(daySlot, day) in month_times_slots"
                        :key="day"
                        class="table_time_step"
                    >
                        <div v-if="data.day.trim() == day">
                            <div
                                v-for="(timeSlot, index) in daySlot.availableSlots"
                                :key="index"
                            >
                                <div
                                    v-if="timeSlot.isUserMeeting"
                                    :class="isWeekend(data.day) ? 'table_time_step__past_data' : 'table_time_step__data'"
                                >
                                    <h6 style="margin: 0">
                                        {{ timeSlot.startTime.slice(0, -3) }}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-calendar>
        </div>
        <div class="calendar-index__day">
            <div class="calendar-index__day-section calendar-index__day-section--align-right">
                <el-button
                    type="primary"
                    round
                    @click="onGoToBooking"
                >
                    Go to Booking
                </el-button>
            </div>
            <div class="calendar-index__day-section">
                <daily-schedule
                    :time-slots="times_slots"
                />
            </div>
        </div>

        <welcome-popup />
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { computed, onMounted, ref, watch } from 'vue'
import InlineSvg from 'vue-inline-svg'
import { ArrowLeft, ArrowRight } from '@element-plus/icons-vue'
import DailySchedule from './DailySchedule'
import { DateTime } from 'luxon'
import userApi from '~/modules/users/userApi'
import { ElMessage } from 'element-plus'
import WelcomePopup from '~/base/components/WelcomePopup.vue'

const SUPPORT_EMAIL = process.env.MIX_SUPPORT_MAIL || 'support@seekingsimple.com'
const BOOKING_STAFF_LINK = 'https://outlook.office.com/bookings/staff'
const router = useRouter()
const month_times_slots = ref([])
const calendar = ref()
const users = ref()
const times_slots = ref([])
const client_levels = ref([])
const loading = ref(false)
const user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const form = ref({
    date: DateTime.now(),
    time: null,
    text: ''
})
const filters = ref({
    client_levels: [],
    users: []
})

const treeClientLevelsRef = ref(null)
const clentLevelsTreeData = ref([])

onMounted(async () => {
    loading.value = true
    // fetchCoachCompanies()
    fetchCoachClientLevels()
    fetchCoachUsers()
    await fetchTimelineByMonth()
    setTimesSlots(true)
    loading.value = false
})

const usersForFilter = computed(() => {
    if (filters.value.client_levels.length) {
        let checkedClientLevels = treeClientLevelsRef.value.getCheckedNodes(false, false)
        return users.value.filter(user => checkedClientLevels.some(client_level => client_level.id === user.client_level_id))
    } else {
        return users.value
    }
})

const selectDate = (val) => {
    calendar.value.selectDate(val)
}

const isWeekend = (date) => {
    return Object.values(month_times_slots.value).some((data) => {
        return data.date === date && !(data.availableSlots.length > 0 && data.availableSlots.some(time => time.available))
    }) || DateTime.now().minus({ day: 1}) > DateTime.fromJSDate(new Date(date))
}

const onGoToBooking = () => {
    window.open(BOOKING_STAFF_LINK, '_blank')
}

// const fetchCoachCompanies = async () => {
//     userApi.getCoachCompanies()
//         .then(response => {
//             companies.value = response.data
//         })
//         .catch((err) => {
//             console.log(err.response)
//             loading.value = false
//         })
// }

const fetchCoachClientLevels = async () => {
    userApi.getCoachClientLevels()
        .then(response => {
            clentLevelsTreeData.value = response.data
        })
        .catch((err) => {
            console.log(err.response)
            loading.value = false
        })
}

const fetchCoachUsers = async () => {
    userApi.getCoachUsers()
        .then(response => {
            users.value = response.data
        })
        .catch((err) => {
            console.log(err.response)
            loading.value = false
        })
}
const fetchTimelineByMonth = async () => {
    let day = DateTime.fromJSDate(new Date(form.value.date))
    const params = {
        month: day.toFormat('LL'),
        year: day.year,
        timezone: user_timezone,
        users: filters.value.users,
        client_levels: filters.value.client_levels
    }
    const { data } = await userApi.getTimeSlotsByMonth(params)
        .catch((err) => {
            console.log(err.response)
            loading.value = false

            if (err.response.status === 425) {
                ElMessage({
                    message: 'Booking system is not created yet, please wait for a couple of minutes.',
                    type: 'warning',
                    duration: 5000,
                })
            } else if (err.response.status === 423) {
                ElMessage({
                    message: 'Booking ID was not created. Please <a href="' + `mailto:${SUPPORT_EMAIL}` + '">contact support</a>.',
                    type: 'warning',
                    dangerouslyUseHTMLString: true,
                    duration: 5000,
                })
            } else {
                ElMessage({
                    message: 'Microsoft server is not reachable.',
                    type: 'error',
                    duration: 5000,
                })
            }
        })
    month_times_slots.value = data

    if (!hasAppointments()) {
        ElMessage({
            message: 'No appointments for selected user(s).',
            type: 'warning',
            duration: 5000,
        })
    }
}

const hasAppointments = () => {
    for (const date in month_times_slots.value) {
        const slots = month_times_slots.value[date].availableSlots
        for (const slot of slots) {
            if (slot.isUserMeeting) {
                return true
            }
        }
    }
    return false
}

const setTimesSlots = (today = false) => {
    let date = DateTime.fromJSDate(new Date(form.value.date))
    const daySlots = _.cloneDeep(month_times_slots.value)[dataFormat(date)]
    times_slots.value = daySlots.availableSlots.map(date => {
        if (date.isUserMeeting) {
            return {
                title: date.title,
                startDateTime: date.startDateTime,
                endTime: date.endTime,
                description: date.description,
                link: date.link,
                time: date.startTime,
                participants: date.participants,
                available: date.available,
                amIHasMeeting: date.isUserMeeting,
                id: date.id
            }
        } else {
            return {
                time: date.startTime,
            }
        }
    })
}

const dataFormat = (date) => {
    return date.toFormat('yyyy-LL-dd')
}

const toNextDayIfWeekend = (date) => {
    let day = DateTime.fromJSDate(new Date(date), { zone: 'UTC' }).setZone(user_timezone, { keepLocalTime: true })
    let nextDay = day.plus({ day: 1 })
    if (isWeekend(dataFormat(day)) && day > DateTime.now()) {
        toNextDayIfWeekend(dataFormat(nextDay))
    } else {
        form.value.date = day
    }
}

watch(() => form.value.date, async (newValue, lastValue) => {
    form.value.time = null
    const newMonthValue = DateTime.fromJSDate(new Date(newValue)).month
    const newYearValue =  DateTime.fromJSDate(new Date(newValue)).year

    const lastMonthValue =  DateTime.fromJSDate(new Date(lastValue)).month
    const lastYearValue = DateTime.fromJSDate(new Date(lastValue)).year

    if (newMonthValue !== lastMonthValue || lastYearValue !== newYearValue) {
        loading.value = true
        times_slots.value = []
        await fetchTimelineByMonth()
        toNextDayIfWeekend(dataFormat(DateTime.fromJSDate(new Date(newValue))))
        loading.value = false

    } else if (DateTime.now() > form.value.date) {
        setTimesSlots(true)
    } else {
        setTimesSlots()
    }
})

watch(() => filters.value, () => {
    loading.value = true
    setTimeout(async () => {
        await fetchTimelineByMonth()
        setTimesSlots()
        loading.value = false
    }, 500)
}, { deep: true })

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
:deep(.el-calendar-table tbody .el-calendar-table__row td.is-selected .el-calendar-day) {
    color: white !important;
    background-color: #32CD99;
    font-weight: 600;
}
:deep(.el-calendar-table td.is-today) {
    color: #484baf;
    font-weight: 600;
}

.calendar-index {
    @media all and (min-width: $s) {
        display: flex;
    }
    &__month,
    &__day {
        @media all and (min-width: $xs) {
            height: calc(100vh - $default-header-height);
        }
        @media all and (min-width: $s) {
            flex: 1;
            overflow: auto;
        }
        @media all and (max-width: $m - 1) {
            padding: 10px 15px;
        }
        @media all and (min-width: $m) {
            padding: 10px 30px;
        }
    }
    &__day {
        &-section {
            padding: 10px 0;
            &--align-right {
                text-align: right;
            }
        }
    }

    .disabled-text {
        color: #C4C4C4;
    }

    .time_slots {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(6, 50px);
        gap: 10px;

        .changed {
            background: #484baf;
            color: white;
            border-color: #484baf;
        }
    }

    .cell-class-name {
        height: 80px !important;
        width: 50px !important;
        overflow: hidden;
    }

    .table_time_step {
        overflow: auto;
        max-height: 50px;

        &::-webkit-scrollbar {
            display: none;
        }

        &__data {
            display: flex;
            flex-wrap: nowrap;
            margin: 2px 0;
            padding: 0 3px;
            text-decoration: none;
            color: #1b1b1b;
        }
        &__past_data {
            display: flex;
            flex-wrap: nowrap;
            margin: 2px 0;
            padding: 0 3px;
            text-decoration: none;
            color: #1b1b1b;
        }
    }

    .time_slot_date {
        padding: 20px 0;
        color: black;
    }
}
</style>
