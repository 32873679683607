import { computed, onMounted, ref } from 'vue'

const UseResize = () => {
    const innerWidth = ref(window.innerWidth)

    const isDesktop = computed(() => innerWidth.value >= 1024)
    const isMobile = computed(() => innerWidth.value <= 768)
    const isDevise = computed(() => innerWidth.value > 768 && innerWidth.value < 1024)

    onMounted(() => {
        window.addEventListener('resize', () => {
            innerWidth.value = window.innerWidth
        })
    })

    return { isDesktop, isMobile, isDevise }
}

export default UseResize
