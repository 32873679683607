import axios from 'axios'

const API_ENDPOINT_SS = window.config.ssURL

export default {

    login(data) {
        return axios.post('login', data)
    },

    forgotPassword(form) {
        return axios.post('password/email', form)
    },

    resetPassword(form) {
        return axios.post('password/reset', form)
    },

    verify(user, query) {
        return axios.post(`email/verify/${user}?${query}`)
    },

    resend() {
        return axios.post('email/resend')
    },

    verifyInviteCode(data) {
        return axios.post('verify-invitecode', data)
    },

    register(data) {
        return axios.post('register', data)
    },

    getAuthCode() {
        // return axios.get(API_ENDPOINT_SS + 'companies/get-auth-code?token=' + process.env.MIX_SS_TOKEN )
        return axios.get('get-auth-code' )
    },

    // ssCoachRegistration(payload) {
    //     return axios.post(API_ENDPOINT_SS + 'coach/coach-registration?token=' + process.env.MIX_SS_TOKEN, payload)
    // },
}
