import axios from 'axios'
import * as types from '../mutation-types'

// state
export const state = {
    user: null,
    remember: false,
    show_welcome_popup: true,
    token: sessionStorage.getItem('token') || localStorage.getItem('token'),
}

// getters
export const getters = {
    user: state => state.user,
    token: state => state.token,
    check: state => !!state.user,
    show_welcome_popup: state => state.show_welcome_popup,
}

// mutations
export const mutations = {
    [types.SAVE_TOKEN] (state, { token, remember }) {
        state.token = token
        if (remember) {
            localStorage.setItem('token', token)
        } else {
            sessionStorage.setItem('token', token)
        }
    },

    [types.FETCH_USER_SUCCESS] (state, { user }) {
        state.user = user
    },

    [types.FETCH_USER_FAILURE] (state) {
        state.token = null
        localStorage.removeItem('token')
        sessionStorage.removeItem('token')
    },

    [types.LOGOUT] (state) {
        state.user = null
        state.token = null

        localStorage.removeItem('token')
        sessionStorage.removeItem('token')
        state.user = null
    },

    updateUserPersonalType (state, personal_type) {
        state.user.personal_type = personal_type
    },

    [types.UPDATE_USER] (state, { user }) {
        state.user = user
    },

    [types.UPDATE_USER_FIELD] (state, value) {
        const key = Object.keys(value)[0]
        state.user[key] = value[key]
    },

    addFirstExperience (state, key) {
        if (! state.user.first_experience) {
            state.user.first_experience = {}
        }
        state.user.first_experience[key] = true
    },

    setShowWelcomePopup (state, value) {
        state.show_welcome_popup = value
    },
}

// actions
export const actions = {
    saveToken( { commit, dispatch }, payload) {
        commit(types.SAVE_TOKEN, payload)
    },

    updateUser( { commit }, payload) {
        commit(types.UPDATE_USER, payload)
    },

    updateUserField( { commit }, value) {
        commit(types.UPDATE_USER_FIELD, value)
    },

    // remove if not needed
    async addPoints(count) {
        commit(types.UPDATE_USER_FIELD, {points: count})
        await axios.post('profile/add-points', {points: count})
    },

    async logout({ commit }) {
        await axios.post('logout')
        commit(types.LOGOUT)
    },

    async fetchOauthUrl(ctx, { provider }) {
        const {data} = await axios.post(`oauth/${provider}`)

        return data.url
    },

    async fetchUser( { commit }) {
        try {
            const {data} = await axios.get('user')
            commit(types.FETCH_USER_SUCCESS, {user: data})
        } catch (err) {
            commit(types.FETCH_USER_FAILURE)
        }
    },

}
