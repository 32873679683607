<template>
    <div class="total-block">
        <div class="total-block__sting">
            <div class="total-block__all">
                <div class="total-block__amount">
                    Total: {{ totalSugar }}
                </div>&nbsp;
                <div class="total-block__description">
                     gm sugar / week
                </div>
            </div>
            <div class="total-block__reduced">
                <div class="total-block__reduced-sugar">
                    {{ totalReducedSugar }} gm saved
                </div>
            </div>
        </div>
        <div class="total-block__sting">
            <div class="total-block__sting">
                <div class="total-block__all">
                    <div class="total-block__amount">
                        Total: {{ totalNetCarbs }}
                    </div>&nbsp;
                    <div class="total-block__description">
                         gm net carbs / week
                    </div>
                </div>
                <div class="total-block__reduced">
                    <div class="total-block__reduced-sugar">
                        {{ totalReducedNetCarbs }} gm saved
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    totalSugar: {
        type: Number,
        required: true,
    },
    totalReducedSugar: {
        type: Number,
        required: true,
    },
    totalNetCarbs: {
        type: Number,
        required: true,
    },
    totalReducedNetCarbs: {
        type: Number,
        required: true,
    }
})
</script>
<style lang="scss">
.total-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 12px;

    &__sting {
        justify-content: space-between;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
    }

    &__all {
        display: flex;
        flex-wrap: nowrap;
    }

    &__amount {
        display: block;
    }

    &__description {
        display: flex;
        flex-wrap: nowrap;
        font-size: 10px;
        align-items: center;
    }

    &__sugar-measurement {
        display: block;
        text-align: start;
        font-size: 10px;
        opacity: .8;
        font-weight: normal;
        margin-bottom: 10px;
    }

    &__reduced-sugar {
        display: block;
    }

    &__reduced-sugar-measurement {
        display: block;
        text-align: end;
        font-weight: normal;
        font-size: 10px;
        opacity: .8;
        @media (max-width: 480px) {
            text-align: start;
        }
    }
}
</style>
