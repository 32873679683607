<template>
    <el-form
        ref="loginForm"
        :model="form"
        :rules="rules"
        class="auth-form"
        @keyup.enter="onSubmit"
    >
        <el-form-item
            prop="email"
            class="el-form-item--default"
            :error="formErrors.get('email')"
        >
            <label class="el-form-item__label--floating-label">
                <el-input
                    v-model="form.email"
                    name="_email"
                    type="email"
                    placeholder="&nbsp;"
                    class="el-input--default el-input--floating-label"
                    autocomplete="email"
                >
                    <template #suffix>
                        Email
                    </template>
                </el-input>
            </label>
        </el-form-item>
        <el-form-item
            prop="password"
            class="el-form-item--default"
            :error="formErrors.get('password')"
        >
            <label class="el-form-item__label--floating-label">
                <el-input
                    v-model="form.password"
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="&nbsp;"
                    class="el-input--floating-label el-input--password"
                    autocomplete="new-password"
                >
                    <template #prefix>
                        <i
                            v-if="form.password.length > 0"
                            class="el-input__prefix-icon fa"
                            :class="{
                                'fa-eye-slash' : !showPassword,
                                'fa-eye' : showPassword,
                            }"
                            @click="showPassword = !showPassword"
                        />
                    </template>
                    <template #suffix>
                        Password
                    </template>
                </el-input>
            </label>
        </el-form-item>
        <el-form-item
            class="el-form-item--default"
        >
            <el-row>
                <el-col :span="12">
                    <el-form-item
                        prop="remember"
                        :error="formErrors.get('remember')"
                        class="some"
                    >
                        <el-checkbox
                            v-model="form.remember"
                            name="remember"
                            label="Remember me"
                        />
                    </el-form-item>
                </el-col>
                <el-col
                    :span="12"
                >
                    <router-link
                        v-if="forgotLink"
                        to="/forgot/password"
                        class="el-link"
                    >
                        Forgot password
                    </router-link>
                </el-col>
            </el-row>
        </el-form-item>
        <el-form-item
            class="el-form-item--default"
        >
            <el-button
                :loading="loading"
                type="primary"
                round
                @click="onSubmit"
            >
                Log In
            </el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import {Errors} from '~/includes/Errors'

export default {
    name: 'LoginForm',
    props: {
        errors: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        forgotLink: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
                remember: false,
                device_name: this.$config.deviceName
            },
            rules: {
                email:    [{required: true, message: 'Please enter your email', trigger: 'blur'}],
                password: [{required: true, message: 'Please enter your password', trigger: 'blur'}],
            },
            formErrors: new Errors(),
            showPassword: false,
        }
    },
    watch: {
        errors: function () {
            this.formErrors.record(this.errors)
        }
    },
    methods: {
        onSubmit(e) {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.formErrors.clear()
                    this.$emit('submit', {
                        ...this.form
                    })
                }
            })
        },
    },
}
</script>
