<template>
    <div
        v-if="tableData"
        v-loading="loading"
        v-for="(data, tableName) in tableData"
        :key="tableName"
    >
        <div v-if="Object.keys(data.data).length">
            <div
                class="default-text--bold"
            >{{ tableName }}</div>
            <el-table
                :data="Object.values(data.data)"
                :row-class-name="tableRowClassName"
                :show-header="false"
                border
                class="el-table--pmo-status"
                row-key="slug"
            >
                <el-table-column>
                    <template #default="scope"><a :href="productLink(scope.row.original_product_gtin)" target="_blank">{{ scope.row.original_product }}</a></template>
                </el-table-column>
                <el-table-column>
                    <template #default="scope">{{ getFrequency(scope.row.amount_values)}}</template>
                </el-table-column>
                <el-table-column>
                    <template #default="scope">{{ scope.row.status ? scope.row.status.charAt(0).toUpperCase() + scope.row.status.slice(1) : '-'}}</template>
                </el-table-column>
                <el-table-column>
                    <template #default="scope">
                        <a
                            v-if="scope.row.new_product"
                            :href="productLink(scope.row.new_product_gtin)"
                            target="_blank"
                        >{{ scope.row.new_product}}
                        </a>
                        <div v-else>-</div>
                    </template>
                </el-table-column>
                <el-table-column>
                    <template #default="scope">{{ scope.row.amount_new_product ? getFrequency(scope.row.amount_new_product) : '-'}}</template>
                </el-table-column>
            </el-table>
            <pmo-summary-total
                :total-sugar="data.total_sugar"
                :total-reduced-sugar="data.saved_sugar"
                :total-net-carbs="data.total_net_carbs"
                :total-reduced-net-carbs="data.saved_net_carbs"
            />
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref,} from 'vue'
import {useRoute} from 'vue-router'
import userApi from '../../userApi'
import PmoSummaryTotal from "./PmoSummaryTotal.vue";

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
})

const SS_DOMAIN = process.env.MIX_SS_URL
const route = useRoute()
const tableData = ref(null)
const loading = ref(true)

onMounted(() => getData())

async function getData() {
    loading.value = true
    const user_id = props.user._id
    const {data} = await userApi.getUserPmo(user_id)
    tableData.value = data
    loading.value = false
}

const productLink  = (product_gtin) => {
    return SS_DOMAIN + '/product/' + product_gtin
}

const tableRowClassName = ({row, rowIndex}) => {
    return row.className ?? ''
}

const getFrequency = (amount_values ) => {
    return amount_values.frequency + ' times per ' + amount_values.frequencyUnit?.toLowerCase() + ', ' + amount_values.amount + ' ' + amount_values.amountUnit
}

</script>
