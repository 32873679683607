import Login from './components/Login'
import ForgotPassword from './components/ForgotPassword'
import PasswordReset from './components/PasswordReset'
import RegisterInvitedUser from './components/InviteRegistration'
import Child from '~/base/components/Child'
import store from '~/store'

const meta = {
    auth: false,
    layout: 'Auth'
}

export default [
    {
        path: '/login',
        component: Login,
        name: 'Login',
        meta: meta,
    },
    {
        path: '/forgot/password',
        component: ForgotPassword,
        name: 'Forgot password',
        meta: meta,
    },
    {
        path: '/password/reset/:token',
        component: PasswordReset,
        name: 'Password reset',
        props: route => ({
            token: route.params.token,
            email: route.query.email
        }),
        meta: meta,
    },
    {
        path: '/invite/user/:invite_code/:email',
        component: RegisterInvitedUser,
        name: 'registerUser',
        meta: meta,
    },
    {
        path: '/logout',
        name: 'logout',
        component: Child,
        meta: {
            auth: true,
            step: 0
        },
        beforeEnter() {
            store.dispatch('auth/logout').then(() => {
                window.location = '/login'
            })
        }
    }
]
