<template>
    <header-default />
    <el-container class="home-container home-container--mob-scroll">
        <el-container>
            <el-main class="home-container__main">
                <child />
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import Child from '~/base/components/Child'
import HeaderDefault from '~/base/components/header-default/HeaderDefault'
</script>
