// shortcut quiz

export const state = {
    robotDialogStatus: 'hidden', // 'hidden', 'fail', 'success', 'final'
}

const robotOptions = {
    hidden: {
        image: '',
        messages: [],
    },
    success: {
        image: '/images/robots/robot_hand_up.svg',
        messages: [
            'Great job! You\'ve unlocked the next section, and earned 10 stars.'
        ],
    },
    fail: {
        image: '/images/robots/robot_hands_down.svg',
        messages: [
            'Please complete the learning chapters, there is some great information in there! You will also earn additional stars for completing each chapter.'
        ],
    },
    final: {
        image: '/images/robots/robot_hand_up.svg',
        messages: [
            'Great Job!  You\'ve finished the final learning module'
        ]
    },
}

// getters
export const getters = {
    robotDialogStatus: state => state.robotDialogStatus,
    robotMessages: state => robotOptions[state.robotDialogStatus].messages,
    robotImage: state => robotOptions[state.robotDialogStatus].image,
}

// mutations
export const mutations = {
    resetRobotDialog (state) {
        state.robotDialogStatus = 'hidden'
    },
    showSuccessRobotDialog (state) {
        state.robotDialogStatus = 'success'
    },
    showFailRobotDialog (state) {
        state.robotDialogStatus = 'fail'
    },
    showFinalRobotDialog (state) {
        state.robotDialogStatus = 'final'
    },
}
