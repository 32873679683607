<template>
    <!-- <div class="el-main__content-top">
        <div class="el-main__content-breadcrumbs">
            <el-breadcrumb
                separator="/"
                class="breadcrumb"
            >
                <el-breadcrumb-item :to="{ name: 'users.index' }">
                    ..
                </el-breadcrumb-item>
                <el-breadcrumb-item>
                    Users
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </div> -->
    <div class="users-page">
        <div class="users-page__filter">
            <filter-top v-model="filters" />
        </div>
        <div class="users-page__info">
            <h1 class="users-page__info-title">
                Your users
            </h1>
            <div class="users-page__info-text default-text">
                Here you can find all users under your management. Select one to see its stats and interactions.
            </div>
        </div>
        <div
            v-loading="loading"
            class="users-page__list"
        >
            <div
                v-for="coachUser in coachUsers"
                :key="coachUser.id"
                class="users-page__list-item"
            >
                <div
                    class="user-card"
                    @click="router.push({name: 'users.details', params: {id: coachUser._id}})"
                >
                    <div class="user-card__image">
                        <img
                            :src="coachUser.photo_url || '/images/ava-default.svg'"
                            class="user-card__image-i"
                            alt="avatar"
                            @error="onErrorImage"
                        >
                    </div>
                    <div class="user-card__name">
                        {{ coachUser.first_name }} {{ coachUser.last_name }}
                    </div>
                    <div class="user-card__info">
                        <div class="user-card__info-item">
                            <span class="user-card__info-item-name">Activation</span> <span class="user-card__info-item-value">{{ getActivationDate(coachUser) }}</span>
                        </div>
                        <div class="user-card__info-item">
                            <span class="user-card__info-item-name">Completion</span> <span class="user-card__info-item-value">{{ getCompletionDate(coachUser) }}</span>
                        </div>
                        <div class="user-card__info-item">
                            <span class="user-card__info-item-name">Persona</span> <span class="user-card__info-item-value">{{ coachUser.personal_type||"—" }}</span>
                        </div>
                        <div class="user-card__info-item">
                            <span class="user-card__info-item-name">Status</span> <span class="user-card__info-item-value">{{ (coachUser.last_step?.path)||"—" }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <h2
                v-if="noUserFound"
                class="users-page__list-title"
            >
                No users found
            </h2>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import {useRouter} from 'vue-router'
import FilterTop from './FilterTop'
import userApi from '../userApi'
import bus from '~/includes/Event'
import store from '~/store'
import _ from 'lodash'


const router = useRouter()

const user = store.getters['auth/user']

const coachUsers = ref([])
const page = ref(1)

const filters = ref({
    company: null,
    search: null,
    sort_by: null,
    persona: null,
    status: null,
})
const loading = ref(false)

const noUserFound = ref(false)

watch(
    () => filters.value,
    val => applySearch(),
    { deep: true }
)

function getUsersData() {
    loading.value = true
    let params = {
        page: page.value,
        ...filters.value
    }
    noUserFound.value = false

    userApi.getCoachUsers(params)
        .then(response => {
            coachUsers.value = response.data

            if (!coachUsers.value || coachUsers.value.length == 0) {
                noUserFound.value = true
            }
        })
        .catch(error => {
            console.log(error)
            noUserFound.value = true
        }).finally(() => {
            loading.value = false
        })
}

const applySearch = _.debounce( function() {
    getUsersData()
}, 900)


function onErrorImage(e) {
    e.target.src = '/images/ava-default.svg'
}

function getActivationDate(user) {
    let activationDate = new Date(user.created_at)
    return _.isDate(activationDate)&&!isNaN(activationDate)? activationDate.toLocaleDateString() : '—'
}
function getCompletionDate(user) {
    let completionDate = new Date(user.created_at)
    completionDate.setDate(completionDate.getDate() + parseInt(user.roc))

    return _.isDate(completionDate)&&!isNaN(completionDate)? completionDate.toLocaleDateString() : '—'
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.users-page {
    $local-light-gray: #dddddd;
    &__filter {
        margin: 0 0 20px;
        border-bottom: 1px solid $local-light-gray;
        @media all and (min-width: 992px) {
            padding: 0 0 15px;
        }
    }
    &__info {
        max-width: 545px;
        margin: 0 auto 20px;
        padding-top: 20px;
        &-title,
        &-text {
            text-align: center;
        }
        &-title {
            margin: 0 0 15px;
            font-size: 18px;
            line-height: 20px;
            color: $dark-middle;
        }
        &-text {
            color: $black;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -12px;
        padding-top: 20px;
        @media all and (max-width: $m - 1) {
            margin: 0 -5px;
        }
        @media (min-width: $m) and (max-width: $xl - 1) {
            margin: 0 -10px;
        }
        @media all and (min-width: $xl) {
            margin: 0 -12px;
        }
        &-title {
            font-size: 18px;
            line-height: 20px;
            text-align: center;
            color: $dark-middle;
        }
        &-item {
            @media all and (max-width: $m - 1) {
                margin: 0 0 10px;
                padding: 0 5px;
            }
            @media all and (max-width: $s - 1) {
                width: calc(100% / 2);
            }
            @media (min-width: $s) and (max-width: $m - 1) {
                width: calc(100% / 4);
            }
            @media (min-width: $m) and (max-width: 1439px) {
                width: calc(100% / 5);
                margin: 0 0 20px;
                padding: 0 10px;
            }
            @media (min-width: 1440px) {
                width: calc(100% / 6);
                margin: 0 0 24px;
                padding: 0 12px;
            }
        }
    }
    .user-card {
        min-height: 100%;
        border: 1px solid $local-light-gray;
        border-radius: 4px;
        box-shadow: 0 6px 8px 0 rgba(0,0,0, 0.15);
        cursor: pointer;
        transition: 0.25s box-shadow ease-in-out;
        @media all and (max-width: $xs) {
            padding: 10px 10px 15px;
        }
        @media (min-width: $xs + 1) and (max-width: $s - 1) {
            padding: 10px 10px 15px;
        }
        @media (min-width: $s) and (max-width: $m - 1) {
            padding: 10px 10px 15px;
        }
        @media all and (min-width: $m) {
            padding: 24px 24px 34px;
        }
        &:hover {
            box-shadow: 0 4px 10px 0 rgba(0,0,0, 0.45);
        }
        &__image {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 84px;
            height: 84px;
            margin: 0 auto 5px;
            border: 4px solid $light-gray;
            border-radius: 50%;
            overflow: hidden;
            padding: 4px;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border: 5px solid $white;
                border-radius: 50%;
            }
            &-i {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        &__name {
            margin-bottom: 15px;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.15px;
            line-height: 1.25;
            text-align: center;
            color: $black;
        }
        &__info {
            &-item {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                font-size: 12px;
                line-height: 1.33;
                @media all and (max-width: $xs) {
                    letter-spacing: 0.3px;
                }
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                &-name {
                    padding-right: 5px;
                    font-weight: bold;
                }
                &-value {
                    width: 50%;
                }
            }
        }

    }
}
</style>
