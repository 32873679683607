import axios from 'axios'

const API_ENDPOINT = 'users'

export default {

    getCoachUsers(params) {
        return axios.get(API_ENDPOINT + '/get-coach-users', {params})
    },

    getCoachUser(id) {
        return axios.get(API_ENDPOINT + '/get-coach-user/' + id)
    },

    getTimeSlotsByMonth(params) {
        return axios.get(API_ENDPOINT + '/get-time-slots-by-month', {params})
    },

    getCoachCompanies() {
        return axios.get(API_ENDPOINT + '/get-coach-companies')
    },

    getCoachClientLevels() {
        return axios.get(API_ENDPOINT + '/get-coach-client-levels')
    },

    getAppointment(params) {
        return axios.get(API_ENDPOINT + '/get-appointment', {params})
    },

    //get-aspitation-user-logs/{ss_user_id}
    getAspirationUserLogs(id, params) {
        return axios.get(API_ENDPOINT + '/logs/get-aspiration-user-logs/' + id, {params})
    },

    getBiomarkers(id, params) {
        return axios.get(API_ENDPOINT + '/biomarkers/get-biomarkers/' + id, {params})
    },

    saveBiomarker(data) {
        return axios.post(API_ENDPOINT + '/biomarkers/save', data)
    },

    getUserPmo(id, params) {
        return axios.get(API_ENDPOINT + '/food-rx/get-user-pmo/' + id, {params})
    },

    getFoodPrescription(ssUserid, params) {
        return axios.get(API_ENDPOINT + '/food-rx/get/' + ssUserid, {params})
    },

    deleteFoodPrescription(id) {
        return axios.post(API_ENDPOINT + '/food-rx/delete/' + id)
    },

    saveFoodPrescription(payload) {
        return axios.post(API_ENDPOINT + '/food-rx/store', payload)
    },
}
