<template>
    <span>
        <el-button
            type="primary"
            size="mini"
            @click="addFoodPrescription"
        >
            Add
        </el-button>
    </span>
    <el-dialog
        v-model="dialogVisible"
        width="80%"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
        <user-food-prescription-form
            :user="user"
            @cancel="handleClose"
        />
    </el-dialog>
</template>

<script setup>
import {ref} from 'vue'
import {useRoute} from 'vue-router'

import UserFoodPrescriptionForm from './UserFoodPrescriptionForm.vue'

const route = useRoute()

const dialogVisible = ref(false)
const user = ref(null)
const props = defineProps({
    user: {
        type: Object,
        required: true
    },
})

function addFoodPrescription() {
    user.value = props.user
    dialogVisible.value = true
}

function handleClose() {
    dialogVisible.value = false
}

</script>
