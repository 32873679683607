import UsersIndex from './components/Index'
import UserDetails from './components/UserDetails'

const meta = {
    auth: true,
    layout: 'DefaultScroll',
}

export default [
    {
        path: 'users',
        name: 'users.index',
        component: UsersIndex,
        meta: meta,
    },
    {
        path: 'users/:id',
        name: 'users.details',
        component: UserDetails,
        meta: {
            auth: true,
            layout: 'DefaultMobScroll'
        },
    }
]

