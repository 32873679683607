import CalendarIndex from './components/CalendarIndex.vue'

const meta = {
    auth: true,
}

export default [
    {
        path: 'calendar',
        name: 'calendar',
        component: CalendarIndex,
        meta: meta,
    },
]
