<template>
    <el-button
        v-if="showSkipButton"
        round
        @click="openQuiz"
    >
        Skip Ahead, I Know This
    </el-button>
<!--    <RobotDialog-->
<!--        v-if="showRobot"-->
<!--        :messages="robotMessages"-->
<!--        robot-image="/images/robots/robot_hand_up.png"-->
<!--        :show-bricks-animation="false"-->
<!--        button-text="Got It"-->
<!--        :button-callback="buttonCallback"-->
<!--    />-->
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import store from "~/store";

// import RobotDialog from '~/modules/quiz/components/RobotDialog'

const props = defineProps({
    module: {
        type: Object,
        required: true
    },
    buttonIndex: {
        type: String,
        default: 0
    }
})

const router = useRouter()

const showRobot = ref(false)
const isFirstClick = ref(!props.buttonIndex)
const showSkipButton = ref(false) // see #1262 (Please hide and comment out the button for all learning modules.)

const robotMessages =ref([
    'Answer a couple of questions to show you understand, and you can unlock the next section.',
    'You\'ll still earn stars, and you can also go back and review the content any time.'
])

function openQuiz() {
    // if (isFirstClick.value) {
    //     //show robot
    //     showRobot.value = true
    // } else {
        routeToQuiz()
    // }
}

function routeToQuiz() {
  store.commit('learning-modules/setActiveQuizId', props.module._id)
  store.commit('learning-modules/changeActiveFunctionality', 'quiz')
}

function buttonCallback() {
    showRobot.value = false
    routeToQuiz()
}

</script>
