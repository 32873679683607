<template>
    <el-dialog
        v-model="showModal"
        :show-close="false"
        width="80%"
        class="el-dialog--meeting-popup meeting-popup"
    >
        <template #header="{}">
            <div class="meeting-popup__header">
                <h3>{{ title }}</h3>
                <div class="meeting-popup__close">
                    <el-button
                        type="primary"
                        :icon="Close"
                        circle
                        class="meeting-popup__close-btn"
                        @click="closeModal"
                    />
                </div>
            </div>
        </template>
        <el-row class="meeting-popup__body">
            <el-col
                :sm="8"
                :xs="24"
                class="meeting-popup__body-col"
            >
                <div class="meeting-popup__body-col-title">
                    Service details
                </div>
                <div class="meeting-popup__body-col-subtitle">
                    <div class="meeting-popup__body-col-subtitle-date">
                        <img
                            src="/images/svg-icons/calendar-icon.svg"
                            alt="calendar"
                            width="16"
                        >
                        <div>
                            {{ date }}
                        </div>
                    </div>
                    <el-button
                        type="primary"
                        circle
                        class="meeting-popup__edit-btn"
                        @click="onEdit"
                    >
                        <inline-svg
                            src="/images/svg-icons/edit-pen.svg"
                            fill="#ffffff"
                        />
                    </el-button>
                </div>
                <div
                    v-loading="descriptionLoading"
                    class="meeting-popup__body-col-text"
                >
                    <div>
                        {{ description }}
                    </div>
                </div>
                <div class="meeting-popup__body-col-button">
                    <el-button
                        v-if="joinUrl"
                        type="primary"
                        round
                        @click="onJoin"
                    >
                        Join
                    </el-button>
                </div>
            </el-col>
            <el-col
                :sm="16"
                :xs="24"
                class="meeting-popup__body-col meeting-popup__body-col--flex"
            >
                <div
                    v-loading="loading"
                    class="meeting-popup__body-col-inner"
                >
                    <div class="meeting-popup__body-col-title">
                        Participants
                    </div>
                    <div class="meeting-popup__body-col-participants">
                        <div
                            v-for="(participant, index) in participants"
                            :key="index"
                            class="users__list-item"
                        >
                            <div
                                class="user-card"
                                @click="router.push({name: 'users.details', params: {id: participant._id}})"
                            >
                                <div class="user-card__image">
                                    <img
                                        :src="participant.photo_url || '/images/ava-default.svg'"
                                        class="user-card__image-i"
                                        alt="avatar"
                                        @error="onErrorImage"
                                    >
                                </div>
                                <div class="user-card__name">
                                    {{ participant.first_name }} {{ participant.last_name }}
                                </div>
                                <div class="user-card__info">
                                    <div class="user-card__info-item">
                                        <span class="user-card__info-item-name">Activation</span> <span
                                            class="user-card__info-item-value"
                                        >{{ getActivationDate(participant) }}</span>
                                    </div>
                                    <div class="user-card__info-item">
                                        <span class="user-card__info-item-name">Completion</span> <span
                                            class="user-card__info-item-value"
                                        >{{ getCompletionDate(participant) }}</span>
                                    </div>
                                    <div class="user-card__info-item">
                                        <span class="user-card__info-item-name">Persona</span> <span
                                            class="user-card__info-item-value"
                                        >{{ participant.personal_type || "—" }}</span>
                                    </div>
                                    <div class="user-card__info-item">
                                        <span class="user-card__info-item-name">Status</span> <span
                                            class="user-card__info-item-value"
                                        >{{
                                            (participant.last_step?.path) || "—"
                                        }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script setup>
import { Close, EditPen } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import InlineSvg from 'vue-inline-svg'

const BOOKING_CALENDAR_LINK = 'https://outlook.office.com/bookings/calendar'
const emit = defineEmits()
const router = useRouter()
const props = defineProps({
    showModal: {
        type: Boolean,
        required: true,
        default: false
    },
    title: {
        type: String,
        required: false,
        default: 'Virtual consultation'
    },
    date: {
        type: String,
        required: true
    },
    description: {
        type: String,
        required: false,
        default: ''
    },
    loading: {
        type: Boolean,
        required: false,
        default: false
    },
    descriptionLoading: {
        type: Boolean,
        required: false,
        default: false
    },
    participants: {
        type: Array,
        default: []
    },
    joinUrl: {
        type: String,
        default: ''
    }
})

function onErrorImage(e) {
    e.target.src = '/images/ava-default.svg'
}

function getActivationDate(user) {
    let activationDate = new Date(user.created_at)
    return _.isDate(activationDate) && !isNaN(activationDate) ? activationDate.toLocaleDateString() : '—'
}

function getCompletionDate(user) {
    let completionDate = new Date(user.created_at)
    completionDate.setDate(completionDate.getDate() + parseInt(user.roc))

    return _.isDate(completionDate) && !isNaN(completionDate) ? completionDate.toLocaleDateString() : '—'
}

const onEdit = () => {
    window.open(BOOKING_CALENDAR_LINK, '_blank')
}

const onJoin = () => {
    window.open(props.joinUrl, '_blank')
}

const closeModal = () => {
    emit('close')
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
$local-light-gray: #dddddd;
.meeting-popup {
    &__close {
        position: absolute;
        @media all and (max-width: $s - 1) {
            top: 5px;
            right: 5px;
        }
        @media all and (min-width: $s) {
            top: 20px;
            right: 20px;
        }

        &-btn {
            padding: 8px;
        }
    }

    &__edit-btn {
        width: 36px;
        height: 36px;

        svg {
            width: 18px;
            height: 18px;
        }
    }

    &__header {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        text-align: center;

        &-logo {
            width: 407px;
            height: auto;

            &-svg {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }

    &__body {
        &-col {
            padding: 15px;

            &-title {
                font-weight: 900;
                margin-bottom: 15px;
            }

            &-subtitle {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                margin-bottom: 15px;

                &-date {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 5px;
                    }
                }
            }

            &-button {
                margin-top: 50px;
                display: flex;
                justify-content: flex-end;

                button {
                    width: 200px;
                }
            }

            &-participants {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .users {
        &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px;
            padding-top: 20px;
            @media all and (max-width: $m - 1) {
                margin: 0 -5px;
            }
            @media (min-width: $m) and (max-width: $xl - 1) {
                margin: 0 -10px;
            }
            @media all and (min-width: $xl) {
                margin: 0 -12px;
            }
            &-title {
                font-size: 18px;
                line-height: 20px;
                text-align: center;
                color: $dark-middle;
            }
            &-item {
                flex: 0 0 calc(33.3333% - 10px);
                box-sizing: border-box;
                margin: 5px;

                @media (min-width: $s + 1) and (max-width: $m) {
                    flex: 0 0 calc(50% - 10px);
                }

                @media all and (max-width: $s) {
                    flex: 0 0 calc(100% - 10px);
                }
            }
        }

    }
}
.user-card {
    min-height: 100%;
    border: 1px solid $local-light-gray;
    border-radius: 4px;
    box-shadow: 0 6px 8px 0 rgba(0,0,0, 0.15);
    cursor: pointer;
    transition: 0.25s box-shadow ease-in-out;
    @media all and (max-width: $xs) {
        padding: 10px 10px 15px;
    }
    @media (min-width: $xs + 1) and (max-width: $s - 1) {
        padding: 10px 10px 15px;
    }
    @media (min-width: $s) and (max-width: $m - 1) {
        padding: 10px 10px 15px;
    }
    @media all and (min-width: $m) {
        padding: 24px 15px 24px;
    }
    &:hover {
        box-shadow: 0 4px 10px 0 rgba(0,0,0, 0.45);
    }
    &__image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 84px;
        height: 84px;
        margin: 0 auto 5px;
        border: 4px solid $light-gray;
        border-radius: 50%;
        overflow: hidden;
        padding: 4px;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border: 5px solid $white;
            border-radius: 50%;
        }
        &-i {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    &__name {
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.15px;
        line-height: 1.25;
        text-align: center;
        color: $black;
    }
    &__info {
        &-item {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            font-size: 12px;
            line-height: 1.33;
            @media all and (max-width: $xs) {
                letter-spacing: 0.3px;
            }
            &:not(:last-child) {
                margin-bottom: 5px;
            }
            &-name {
                padding-right: 5px;
                font-weight: bold;
            }
            &-value {
                width: 50%;
            }
        }
    }

}
</style>
