<template>
    <header class="header">
        <div class="header__left-side">
            <div
                :key="route.name"
                class="header__menu"
                @click="router.back()"
            >
                <i class="header__menu-icon fa-solid fa-arrow-left" />
            </div>
        </div>
        <div class="header__center">
            <header-title />
        </div>
        <div class="header__right-side" />
    </header>
</template>

<script setup>
import HeaderTitle from '~/base/components/HeaderTitle'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()

</script>
