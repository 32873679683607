<template>
    <div class="header-controls">
        <div
            v-for="item in controlsList"
            :key="item.title"

        >
            <div v-if="!(item.onlyForMobile && isDesktop)" class="item">
                <template v-if="item.title === 'SS Login'">
                    <a
                        class="header-controls__link"
                        :href="redirectToSS"
                        target="_blank"
                    >
                    <span class="header-controls__link-icon">
                        <inline-svg
                            class="header-controls__link-icon-svg"
                            :src="item.icon"
                        />
                    </span>
                        <span class="header-controls__link-title">
                        {{ item.title }}
                    </span>
                    </a>
                </template>
                <template v-else>
                    <router-link
                        :to="{ name: item.routeName }"

                        class="header-controls__link"
                    >
                    <span class="header-controls__link-icon">
                        <inline-svg
                            class="header-controls__link-icon-svg"
                            :class="isCurrenPage(item.routeName) ? 'is-active' : ''"
                            :src="item.icon"
                        />
                    </span>
                        <span
                            class="header-controls__link-title"
                            :class="isCurrenPage(item.routeName) ? 'is-active' : ''"
                        >
                        {{ item.title }}
                    </span>
                    </router-link>
                </template>
            </div>
        </div>
        <div v-if="isDesktop" class="header-controls__top-controls">
            <div class="header-controls__user">
                <div class="header-controls__user-image">
                    <el-dropdown trigger="click">
                        <div
                            class="header-controls__link"
                        >
                            <span class="header-controls__link-icon">
                                <inline-svg
                                    class="header-controls__link-icon-svg"
                                    src="/images/svg-icons/user-icon.svg"
                                />
                            </span>
                            <span class="header-controls__link-title">
                                Profile
                            </span>
                        </div>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <div class="header-controls__user-info">
                                    {{ user.first_name }}
                                </div>
                                <el-dropdown-item @click="router.push({name: 'profile.info'})">
                                    Edit profile
                                </el-dropdown-item>
                                <el-dropdown-item @click="router.push({name: 'profile.reset-password'})">
                                    Change password
                                </el-dropdown-item>
                                <el-dropdown-item
                                    divided
                                    @click="logout"
                                >
                                    Log Out
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import {computed} from 'vue'
import {ElMessageBox} from 'element-plus'
import bus from '~/includes/Event'
import store from '~/store'
import {useRouter} from 'vue-router'
import UseResize from "~/composable/UseResize";

const router = useRouter()
const user = store.getters['auth/user']
const redirectToSS = computed(() => {
    return process.env.MIX_SS_URL + '/login'
})
const {isDesktop} = UseResize()
const props = defineProps({
    controlsList: {
        type: Array,
        default: () => {
            return []
        }
    }
})

const isCurrenPage = (name) => router?.currentRoute?.value?.name === name

function logout() {
    ElMessageBox.confirm('Confirm LogOut', 'You will be logged out', {
        confirmButtonText: 'Ok',
        cancelButtonText: 'Cancel',
    }).then(async () => {
        bus.$emit('logout')
        await store.dispatch('auth/logout')
        router.push({name: 'Login'})
    })
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.item {
    margin-right: 15px;
    @media all and (max-width: $xs - 1) {
        margin-right: 10px;
    }
    @media all and (min-width: $s) {
        margin-right: 40px;
    }
}

.header-controls {
    $block-name: &;
    display: flex;

    &__link {
        display: flex;
        align-items: flex-start;
        text-decoration: none;
        cursor: pointer;

        .is-active {
            color: #32CD99;
            fill: #32CD99;
        }

        &:hover {
            #{$block-name} {
                &__link {
                    &-title {
                        border-bottom-color: $indigo;
                    }
                }
            }
        }

        &-icon {
            display: block;
            width: 16px;
            height: 16px;
            margin-right: 5px;
            @media all and (min-width: $xs) {
                margin-right: 10px;
            }

            &-svg {
                fill: $indigo;
            }
        }

        &-title {
            border-bottom: 2px solid transparent;
            font-size: 14px;
            font-weight: bold;
            line-height: 1.4;
            color: $indigo;
            transition: 0.25s border-bottom-color ease-in-out;
        }
    }

    &__user {
        display: flex;
        align-items: center;

        &-info {
            text-align: end;
            padding: 10px;
            font-weight: bold;
            max-width: 40ch;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-image {
            cursor: pointer;
            border-bottom: 2px solid transparent;
            transition: 0.25s border-bottom-color ease-in-out;
        }
    }
}
</style>
