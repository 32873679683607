<template>
    <el-dialog
        v-model="dialogVisible"
        width="80%"
        :before-close="handleClose"
    >
        <user-food-prescription-form
            :user="user"
            :close-on-click-modal="false"
            :prescription="prescription"
            @cancel="handleClose"
        />
    </el-dialog>
</template>

<script setup>
import {ref} from 'vue'
import {useRoute} from 'vue-router'

import UserFoodPrescriptionForm from './UserFoodPrescriptionForm.vue'

const route = useRoute()
const emit = defineEmits()
const dialogVisible = ref(false)
const user = ref(null)
const props = defineProps({
    user: {
        type: Object,
        required: true
    },
    prescription: {
        type: Object,
        required: false
    }
})

function handleClose() {
    dialogVisible.value = false
    emit('handleClose')
}

function openDialog() {
    dialogVisible.value = true;
}

defineExpose({
    openDialog,
});

</script>
