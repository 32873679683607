<template>
    <el-table
        v-loading="loading"
        :data="tableData"
        :row-class-name="tableRowClassName"
        border
        class="el-table--pmo-status"
        row-key="slug"
    >
        <el-table-column
            fixed
            prop="pmo_status"
            label="PMO Status"
            min-width="170"
        />
        <el-table-column
            prop="sub_cat"
            label="Sub Cat."
            min-width="110"
        />
        <el-table-column
            prop="scanned"
            label="Scanned"
            min-width="110"
        />
        <el-table-column
            prop="replaced"
            label="Replaced"
            min-width="110"
        />
        <el-table-column
            prop="retained"
            label="Retained"
            min-width="110"
        />
        <el-table-column
            prop="reduced"
            label="Reduced"
            min-width="110"
        />
        <el-table-column
            prop="removed"
            label="Removed"
            min-width="110"
        />
        <el-table-column
            prop="rated"
            label="Rated"
            min-width="110"
        />
    </el-table>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import pmoApi from '../pmoApi'
import bus from '~/includes/Event'
import _ from 'lodash'
// import FilterTop from './FilterTop'

const route = useRoute()

const tableData = ref(null)
const companyData = ref(null)
const loading = ref(true)

const filters = ref({
    dateRange: ''
})

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
})

onMounted(() => getData())

watch(() => filters.value, val => {
    getData()
},
{ deep: true }
)


async function getData() {
    loading.value = true
    const user_id = props.user._id
    const {data} = await pmoApi.getUserPmoStatus({user_id: user_id, ...filters.value})

    tableData.value = data
    loading.value = false
}

const tableRowClassName = ({row, rowIndex}) => {
    return row.className ?? ''
}
</script>
