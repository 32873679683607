
import { fromPairs } from 'lodash';
<template>
    <span>
        <el-button
            type="primary"
            size="mini"
            @click="addBiomarker"
        >
            Add
        </el-button>
    </span>
    <el-dialog
        v-model="dialogVisible"
        :close-on-click-modal="false"
        v-loading="loading"
        width="80%"
        :before-close="handleClose"
    >
        <user-biomarker-form
            :key="userBiomarkerFormKey"
            :user="user"
            :biomarker="biomarker"
            @cancel="handleClose"
            @biomarker-updated="handleClose"
        />
    </el-dialog>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'

import UserBiomarkerForm from './UserBiomarkerForm'

const route = useRoute()

const dialogVisible = ref(false)
const user = ref(null)
const biomarker = ref(null)
const userBiomarkerFormKey = ref(0)

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
})

function addBiomarker() {
    user.value = props.user
    biomarker.value = null
    dialogVisible.value = true
}

function handleClose() {
    dialogVisible.value = false
    userBiomarkerFormKey.value++
}

</script>
