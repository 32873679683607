<template>
    <el-table
        v-loading="loading"
        :data="tableData"
        :row-class-name="tableRowClassName"
        border
        class="el-table--aspiration"
        row-key="created_at"
    >
        <el-table-column
            prop="created_at"
            label="Date"
            min-width="110"
        >
            <template #default="scope">
                {{ formatDateTime(scope.row.created_at) }}
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Name"
            min-width="170"
        >
            <template #default="scope">
                <span v-html="scope.row.name" />
            </template>
        </el-table-column>
        <el-table-column
            prop="value"
            label="Choice"
            min-width="110"
        />
    </el-table>
    <el-pagination
        v-if="total > 0 && total > filters.per_page"
        background
        layout="prev, pager, next"
        :page-size="filters.per_page"
        :total="total"
        @current-change="handleCurrentChange"
    />
</template>

<script setup>
import {onMounted, ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import userApi from '../userApi'
import bus from '~/includes/Event'
import _ from 'lodash'
// import FilterTop from './FilterTop'

const route = useRoute()

const tableData = ref(null)
const companyData = ref(null)
const loading = ref(true)

const filters = ref({
    // dateRange: '',
    page: 1,
    per_page: 15,
})

const total = ref(0)

const props = defineProps({
    user: {
        type: Object,
        required: true
    },
})

const currentTime = ref(null)
const colorCount = ref(0)

onMounted(() => getData())

watch(() => filters.value, val => {
    getData()
},
{ deep: true }
)


async function getData() {
    loading.value = true
    const user_id = props.user._id

    let params = {
        ...filters.value,
        page: filters.value.page ?? 1,
        per_page: filters.value.per_page ?? 10,
    }

    const {data} = await userApi.getAspirationUserLogs(user_id, params)

    tableData.value = data.data
    total.value = data.total
    loading.value = false
}

const tableRowClassName = ({row, rowIndex}) => {
    let rowTime = formatDateTime(row.created_at)

    if (rowTime !== currentTime.value) {
        currentTime.value = rowTime
        colorCount.value = colorCount.value + 1
    }

    return colorCount.value%2?(row.className??''):'el-table--aspiration__row'
}

function formatDateTime(date) {
    let d = new Date(date)

    // M/d/yyyy h:mm:ss
    let month = _.padStart(d.getMonth() + 1, 2, '0')
    let day = _.padStart(d.getDate(), 2, '0')
    let year = d.getFullYear()
    let hour = _.padStart(d.getHours(), 2, '0')
    let minute = _.padStart(d.getMinutes(), 2, '0')
    let  second = _.padStart(d.getSeconds(), 2, '0')

    return `${month}/${day}/${year} ${hour}:${minute}:${second}`
}

function handleCurrentChange(page) {
    filters.value.page = page
    getData()
}

</script>
