<template>
    <div
        :class="moduleStatus"
        class="learning-module"
    >
        <div
            class="learning-module__module-block module-block module-block--vertical"
        >
            <el-progress
                type="circle"
                :percentage="progressValue"
                :color="'#00315D'"
                :stroke-width="4"
                :width="84"
            >
                <div class="module-block__image learning-module__image">
                    <div
                        class="module-block__image-wrap"
                        @click="toggleShowChapters"
                    >
                        <img
                            :src="module.icon_url"
                            class="module-block__image-i"
                            alt=""
                        >
                    </div>
                    <Badge
                        :status="moduleStatus"
                        :completed-chapters="completedChapters"
                    />
                </div>
            </el-progress>
            <div class="module-block__title">
                {{ module.title }}
            </div>
            <div class="module-block__description">
                {{ module.description }}
            </div>
        </div>
        <div
            v-if="showChapters && module.chapters.length > 0"
            class="learning-module__chapter-section"
        >
            <div class="learning-module__chapter-list">
                <div
                    v-for="chapter in module.chapters"
                    :key="chapter._id"
                    class="learning-module__chapter"
                >
                    <LearningModuleChapter
                        :chapter="chapter"
                    />
                </div>
            </div>
            <div class="learning-module__button">
                <ShortcutQuiz
                    v-if="isActive && !isAllDone"
                    :module="module"
                    :button-index="module._id"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import LearningModuleChapter from './LearningModuleChapter'
import ShortcutQuiz from './shortcut-quiz'
import { computed, ref, onMounted, watch } from 'vue'
import Badge from './Badge'
import learningModuleApi from '~/modules/learning-modules/learningModuleApi'
import bus from '~/includes/Event'
import store from "~/store";

const name = 'LearningModuleItem'
const props = defineProps({
    module: {
        type: Object,
        required: true,
    },
    isActive: {
        type: Boolean,
        default: false,
    },
    isAllDone: {
        type: Boolean,
        default: false,
    }
})

const showChapters = ref(props.isActive)
const progressValue = computed(() => completedChapters.value / props.module.chapters.length * 100)

function chapterActivator() {
    if (props.isActive) {
        const activeChapter = props.module.chapters.find(chapter => !chapter.completed)
        if (activeChapter) {
            store.commit('learning-modules/setActiveLessonId', activeChapter.lesson._id)
        } else if (props.isAllDone) {
            const latsChapter = props.module.chapters[props.module.chapters.length - 1]
            store.commit('learning-modules/setActiveLessonId', latsChapter?.lesson?._id)
        }
        learningModuleApi.addModuleStep(props.module._id)
    }
    bus.$on('toggle-active-module-item', toggleShowChapters)
}

const completedChapters = computed(
    () => _.reduce(props.module.chapters, function (sum, chapter) {
        return sum + Number(chapter.completed)
    }, 0)
)

const moduleStatus = computed(
    () => {
        return props.module.completed ? 'done' :
            props.isActive ? 'active' :
                'locked'
    }
)

function toggleShowChapters() {
    showChapters.value = !showChapters.value
}

watch(
    () => props.isActive,
    val => {
        showChapters.value = val
    },
    { immediate: true }
)

defineExpose({
    chapterActivator,
});
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.learning-module {
    $block-name: &;
    text-align: center;
    display: block;

    &__module-block {
        max-width: 320px;
        margin-bottom: 30px;
        @media all and (min-width: $s) {
            max-width: 400px;
        }
    }

    &__chapter {
        width: 50%;
        padding: 0 10px;

        &-section {
            margin-bottom: 20px;
            border-bottom: 1px solid $light-gray;
            padding-bottom: 30px;
        }

        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;
        }
    }

    &__image {
        border-color: transparent !important;
        background-color: transparent !important;

        &:before {
            display: none;
        }
    }

    &__button {
        padding-top: 25px;
    }

    &.active,
    &.done {
        .module-block {
            &__image {
                border-color: $light-gray;
                cursor: pointer;

                &:after {
                    background-color: $blue;
                }
            }

            &__title {
                color: $dark;
            }
        }
    }

    &.done {
        .module-block {
            &__image {
                border-color: $blue;
            }
        }
    }

    &.locked {
        pointer-events: none;

        .module-block {
            &__image {
                &:after {
                    background-color: $light-blue;
                }
            }

            &__description {
                color: $light-gray;
            }
        }
    }
}
</style>
