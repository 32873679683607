import axios from 'axios'
// import store from '~/store'

const API_ENDPOINT = 'pmo/categories'

export default {

    getUserPmoStatus(params) {
        return axios.get(API_ENDPOINT + '/get-user-pmo-status', {params})
    },


}
