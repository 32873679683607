import PrivacyPolicy from './components/PrivacyPolicy'
import TermsOfUse from './components/TermsOfUse'

const meta = {
    auth: false,
    layout: 'Auth'
}

export default [
    {
        path: 'terms-of-use',
        name: 'static-pages.terms-of-use',
        component: TermsOfUse,
        meta,
    },
    {
        path: 'privacy-policy',
        name: 'static-pages.privacy-policy',
        component: PrivacyPolicy,
        meta,
    },
]
